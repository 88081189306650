@charset "UTF-8";
@import url(~material-icons/iconfont/material-icons.css);
.background-transparent {
  background: transparent !important; }

.border-radius-0 {
  border-radius: 0px !important; }

.fs-0 {
  font-size: 0px !important; }
  .fs-0.light-text {
    font-family: "montserrat-light"; }
  .fs-0.regular-text {
    font-family: "montserrat-regular"; }
  .fs-0.medium-text {
    font-family: "montserrat-medium"; }
  .fs-0.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-0.bold-text {
    font-family: "montserrat-bold"; }

.pa-0 {
  padding: 0px !important; }

.pr-0 {
  padding-right: 0px !important; }

.pl-0 {
  padding-left: 0px !important; }

.pt-0 {
  padding-top: 0px !important; }

.pb-0 {
  padding-bottom: 0px !important; }

.plr-0 {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.prl-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.ptb-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.pbt-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.ma-0 {
  margin: 0px !important; }

.mr-0 {
  margin-right: 0px !important; }

.mt-0 {
  margin-top: 0px !important; }

.mb-0 {
  margin-bottom: 0px !important; }

.ml-0 {
  margin-left: 0px !important; }

.mlr-0 {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.mrl-0 {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.mtb-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.mbt-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.border-radius-1 {
  border-radius: 1px !important; }

.fs-1 {
  font-size: 1px !important; }
  .fs-1.light-text {
    font-family: "montserrat-light"; }
  .fs-1.regular-text {
    font-family: "montserrat-regular"; }
  .fs-1.medium-text {
    font-family: "montserrat-medium"; }
  .fs-1.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-1.bold-text {
    font-family: "montserrat-bold"; }

.pa-1 {
  padding: 1px !important; }

.pr-1 {
  padding-right: 1px !important; }

.pl-1 {
  padding-left: 1px !important; }

.pt-1 {
  padding-top: 1px !important; }

.pb-1 {
  padding-bottom: 1px !important; }

.plr-1 {
  padding-left: 1px !important;
  padding-right: 1px !important; }

.prl-1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important; }

.ptb-1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important; }

.pbt-1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important; }

.ma-1 {
  margin: 1px !important; }

.mr-1 {
  margin-right: 1px !important; }

.mt-1 {
  margin-top: 1px !important; }

.mb-1 {
  margin-bottom: 1px !important; }

.ml-1 {
  margin-left: 1px !important; }

.mlr-1 {
  margin-left: 1px !important;
  margin-right: 1px !important; }

.mrl-1 {
  margin-left: 1px !important;
  margin-right: 1px !important; }

.mtb-1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important; }

.mbt-1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important; }

.border-radius-2 {
  border-radius: 2px !important; }

.fs-2 {
  font-size: 2px !important; }
  .fs-2.light-text {
    font-family: "montserrat-light"; }
  .fs-2.regular-text {
    font-family: "montserrat-regular"; }
  .fs-2.medium-text {
    font-family: "montserrat-medium"; }
  .fs-2.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-2.bold-text {
    font-family: "montserrat-bold"; }

.pa-2 {
  padding: 2px !important; }

.pr-2 {
  padding-right: 2px !important; }

.pl-2 {
  padding-left: 2px !important; }

.pt-2 {
  padding-top: 2px !important; }

.pb-2 {
  padding-bottom: 2px !important; }

.plr-2 {
  padding-left: 2px !important;
  padding-right: 2px !important; }

.prl-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important; }

.ptb-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important; }

.pbt-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important; }

.ma-2 {
  margin: 2px !important; }

.mr-2 {
  margin-right: 2px !important; }

.mt-2 {
  margin-top: 2px !important; }

.mb-2 {
  margin-bottom: 2px !important; }

.ml-2 {
  margin-left: 2px !important; }

.mlr-2 {
  margin-left: 2px !important;
  margin-right: 2px !important; }

.mrl-2 {
  margin-left: 2px !important;
  margin-right: 2px !important; }

.mtb-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important; }

.mbt-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important; }

.border-radius-3 {
  border-radius: 3px !important; }

.fs-3 {
  font-size: 3px !important; }
  .fs-3.light-text {
    font-family: "montserrat-light"; }
  .fs-3.regular-text {
    font-family: "montserrat-regular"; }
  .fs-3.medium-text {
    font-family: "montserrat-medium"; }
  .fs-3.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-3.bold-text {
    font-family: "montserrat-bold"; }

.pa-3 {
  padding: 3px !important; }

.pr-3 {
  padding-right: 3px !important; }

.pl-3 {
  padding-left: 3px !important; }

.pt-3 {
  padding-top: 3px !important; }

.pb-3 {
  padding-bottom: 3px !important; }

.plr-3 {
  padding-left: 3px !important;
  padding-right: 3px !important; }

.prl-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important; }

.ptb-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important; }

.pbt-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important; }

.ma-3 {
  margin: 3px !important; }

.mr-3 {
  margin-right: 3px !important; }

.mt-3 {
  margin-top: 3px !important; }

.mb-3 {
  margin-bottom: 3px !important; }

.ml-3 {
  margin-left: 3px !important; }

.mlr-3 {
  margin-left: 3px !important;
  margin-right: 3px !important; }

.mrl-3 {
  margin-left: 3px !important;
  margin-right: 3px !important; }

.mtb-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important; }

.mbt-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important; }

.border-radius-4 {
  border-radius: 4px !important; }

.fs-4 {
  font-size: 4px !important; }
  .fs-4.light-text {
    font-family: "montserrat-light"; }
  .fs-4.regular-text {
    font-family: "montserrat-regular"; }
  .fs-4.medium-text {
    font-family: "montserrat-medium"; }
  .fs-4.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-4.bold-text {
    font-family: "montserrat-bold"; }

.pa-4 {
  padding: 4px !important; }

.pr-4 {
  padding-right: 4px !important; }

.pl-4 {
  padding-left: 4px !important; }

.pt-4 {
  padding-top: 4px !important; }

.pb-4 {
  padding-bottom: 4px !important; }

.plr-4 {
  padding-left: 4px !important;
  padding-right: 4px !important; }

.prl-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important; }

.ptb-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important; }

.pbt-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important; }

.ma-4 {
  margin: 4px !important; }

.mr-4 {
  margin-right: 4px !important; }

.mt-4 {
  margin-top: 4px !important; }

.mb-4 {
  margin-bottom: 4px !important; }

.ml-4 {
  margin-left: 4px !important; }

.mlr-4 {
  margin-left: 4px !important;
  margin-right: 4px !important; }

.mrl-4 {
  margin-left: 4px !important;
  margin-right: 4px !important; }

.mtb-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important; }

.mbt-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important; }

.border-radius-5 {
  border-radius: 5px !important; }

.fs-5 {
  font-size: 5px !important; }
  .fs-5.light-text {
    font-family: "montserrat-light"; }
  .fs-5.regular-text {
    font-family: "montserrat-regular"; }
  .fs-5.medium-text {
    font-family: "montserrat-medium"; }
  .fs-5.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-5.bold-text {
    font-family: "montserrat-bold"; }

.pa-5 {
  padding: 5px !important; }

.pr-5 {
  padding-right: 5px !important; }

.pl-5 {
  padding-left: 5px !important; }

.pt-5 {
  padding-top: 5px !important; }

.pb-5 {
  padding-bottom: 5px !important; }

.plr-5 {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.prl-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.ptb-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pbt-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.ma-5 {
  margin: 5px !important; }

.mr-5 {
  margin-right: 5px !important; }

.mt-5 {
  margin-top: 5px !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.ml-5 {
  margin-left: 5px !important; }

.mlr-5 {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.mrl-5 {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.mtb-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.mbt-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.border-radius-6 {
  border-radius: 6px !important; }

.fs-6 {
  font-size: 6px !important; }
  .fs-6.light-text {
    font-family: "montserrat-light"; }
  .fs-6.regular-text {
    font-family: "montserrat-regular"; }
  .fs-6.medium-text {
    font-family: "montserrat-medium"; }
  .fs-6.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-6.bold-text {
    font-family: "montserrat-bold"; }

.pa-6 {
  padding: 6px !important; }

.pr-6 {
  padding-right: 6px !important; }

.pl-6 {
  padding-left: 6px !important; }

.pt-6 {
  padding-top: 6px !important; }

.pb-6 {
  padding-bottom: 6px !important; }

.plr-6 {
  padding-left: 6px !important;
  padding-right: 6px !important; }

.prl-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important; }

.ptb-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important; }

.pbt-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important; }

.ma-6 {
  margin: 6px !important; }

.mr-6 {
  margin-right: 6px !important; }

.mt-6 {
  margin-top: 6px !important; }

.mb-6 {
  margin-bottom: 6px !important; }

.ml-6 {
  margin-left: 6px !important; }

.mlr-6 {
  margin-left: 6px !important;
  margin-right: 6px !important; }

.mrl-6 {
  margin-left: 6px !important;
  margin-right: 6px !important; }

.mtb-6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important; }

.mbt-6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important; }

.border-radius-7 {
  border-radius: 7px !important; }

.fs-7 {
  font-size: 7px !important; }
  .fs-7.light-text {
    font-family: "montserrat-light"; }
  .fs-7.regular-text {
    font-family: "montserrat-regular"; }
  .fs-7.medium-text {
    font-family: "montserrat-medium"; }
  .fs-7.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-7.bold-text {
    font-family: "montserrat-bold"; }

.pa-7 {
  padding: 7px !important; }

.pr-7 {
  padding-right: 7px !important; }

.pl-7 {
  padding-left: 7px !important; }

.pt-7 {
  padding-top: 7px !important; }

.pb-7 {
  padding-bottom: 7px !important; }

.plr-7 {
  padding-left: 7px !important;
  padding-right: 7px !important; }

.prl-7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important; }

.ptb-7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important; }

.pbt-7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important; }

.ma-7 {
  margin: 7px !important; }

.mr-7 {
  margin-right: 7px !important; }

.mt-7 {
  margin-top: 7px !important; }

.mb-7 {
  margin-bottom: 7px !important; }

.ml-7 {
  margin-left: 7px !important; }

.mlr-7 {
  margin-left: 7px !important;
  margin-right: 7px !important; }

.mrl-7 {
  margin-left: 7px !important;
  margin-right: 7px !important; }

.mtb-7 {
  margin-top: 7px !important;
  margin-bottom: 7px !important; }

.mbt-7 {
  margin-top: 7px !important;
  margin-bottom: 7px !important; }

.border-radius-8 {
  border-radius: 8px !important; }

.fs-8 {
  font-size: 8px !important; }
  .fs-8.light-text {
    font-family: "montserrat-light"; }
  .fs-8.regular-text {
    font-family: "montserrat-regular"; }
  .fs-8.medium-text {
    font-family: "montserrat-medium"; }
  .fs-8.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-8.bold-text {
    font-family: "montserrat-bold"; }

.pa-8 {
  padding: 8px !important; }

.pr-8 {
  padding-right: 8px !important; }

.pl-8 {
  padding-left: 8px !important; }

.pt-8 {
  padding-top: 8px !important; }

.pb-8 {
  padding-bottom: 8px !important; }

.plr-8 {
  padding-left: 8px !important;
  padding-right: 8px !important; }

.prl-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important; }

.ptb-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important; }

.pbt-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important; }

.ma-8 {
  margin: 8px !important; }

.mr-8 {
  margin-right: 8px !important; }

.mt-8 {
  margin-top: 8px !important; }

.mb-8 {
  margin-bottom: 8px !important; }

.ml-8 {
  margin-left: 8px !important; }

.mlr-8 {
  margin-left: 8px !important;
  margin-right: 8px !important; }

.mrl-8 {
  margin-left: 8px !important;
  margin-right: 8px !important; }

.mtb-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important; }

.mbt-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important; }

.border-radius-9 {
  border-radius: 9px !important; }

.fs-9 {
  font-size: 9px !important; }
  .fs-9.light-text {
    font-family: "montserrat-light"; }
  .fs-9.regular-text {
    font-family: "montserrat-regular"; }
  .fs-9.medium-text {
    font-family: "montserrat-medium"; }
  .fs-9.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-9.bold-text {
    font-family: "montserrat-bold"; }

.pa-9 {
  padding: 9px !important; }

.pr-9 {
  padding-right: 9px !important; }

.pl-9 {
  padding-left: 9px !important; }

.pt-9 {
  padding-top: 9px !important; }

.pb-9 {
  padding-bottom: 9px !important; }

.plr-9 {
  padding-left: 9px !important;
  padding-right: 9px !important; }

.prl-9 {
  padding-top: 9px !important;
  padding-bottom: 9px !important; }

.ptb-9 {
  padding-top: 9px !important;
  padding-bottom: 9px !important; }

.pbt-9 {
  padding-top: 9px !important;
  padding-bottom: 9px !important; }

.ma-9 {
  margin: 9px !important; }

.mr-9 {
  margin-right: 9px !important; }

.mt-9 {
  margin-top: 9px !important; }

.mb-9 {
  margin-bottom: 9px !important; }

.ml-9 {
  margin-left: 9px !important; }

.mlr-9 {
  margin-left: 9px !important;
  margin-right: 9px !important; }

.mrl-9 {
  margin-left: 9px !important;
  margin-right: 9px !important; }

.mtb-9 {
  margin-top: 9px !important;
  margin-bottom: 9px !important; }

.mbt-9 {
  margin-top: 9px !important;
  margin-bottom: 9px !important; }

.border-radius-10 {
  border-radius: 10px !important; }

.fs-10 {
  font-size: 10px !important; }
  .fs-10.light-text {
    font-family: "montserrat-light"; }
  .fs-10.regular-text {
    font-family: "montserrat-regular"; }
  .fs-10.medium-text {
    font-family: "montserrat-medium"; }
  .fs-10.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-10.bold-text {
    font-family: "montserrat-bold"; }

.pa-10 {
  padding: 10px !important; }

.pr-10 {
  padding-right: 10px !important; }

.pl-10 {
  padding-left: 10px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.plr-10 {
  padding-left: 10px !important;
  padding-right: 10px !important; }

.prl-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.ptb-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.pbt-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.ma-10 {
  margin: 10px !important; }

.mr-10 {
  margin-right: 10px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.ml-10 {
  margin-left: 10px !important; }

.mlr-10 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.mrl-10 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.mtb-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.mbt-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.border-radius-11 {
  border-radius: 11px !important; }

.fs-11 {
  font-size: 11px !important; }
  .fs-11.light-text {
    font-family: "montserrat-light"; }
  .fs-11.regular-text {
    font-family: "montserrat-regular"; }
  .fs-11.medium-text {
    font-family: "montserrat-medium"; }
  .fs-11.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-11.bold-text {
    font-family: "montserrat-bold"; }

.pa-11 {
  padding: 11px !important; }

.pr-11 {
  padding-right: 11px !important; }

.pl-11 {
  padding-left: 11px !important; }

.pt-11 {
  padding-top: 11px !important; }

.pb-11 {
  padding-bottom: 11px !important; }

.plr-11 {
  padding-left: 11px !important;
  padding-right: 11px !important; }

.prl-11 {
  padding-top: 11px !important;
  padding-bottom: 11px !important; }

.ptb-11 {
  padding-top: 11px !important;
  padding-bottom: 11px !important; }

.pbt-11 {
  padding-top: 11px !important;
  padding-bottom: 11px !important; }

.ma-11 {
  margin: 11px !important; }

.mr-11 {
  margin-right: 11px !important; }

.mt-11 {
  margin-top: 11px !important; }

.mb-11 {
  margin-bottom: 11px !important; }

.ml-11 {
  margin-left: 11px !important; }

.mlr-11 {
  margin-left: 11px !important;
  margin-right: 11px !important; }

.mrl-11 {
  margin-left: 11px !important;
  margin-right: 11px !important; }

.mtb-11 {
  margin-top: 11px !important;
  margin-bottom: 11px !important; }

.mbt-11 {
  margin-top: 11px !important;
  margin-bottom: 11px !important; }

.border-radius-12 {
  border-radius: 12px !important; }

.fs-12 {
  font-size: 12px !important; }
  .fs-12.light-text {
    font-family: "montserrat-light"; }
  .fs-12.regular-text {
    font-family: "montserrat-regular"; }
  .fs-12.medium-text {
    font-family: "montserrat-medium"; }
  .fs-12.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-12.bold-text {
    font-family: "montserrat-bold"; }

.pa-12 {
  padding: 12px !important; }

.pr-12 {
  padding-right: 12px !important; }

.pl-12 {
  padding-left: 12px !important; }

.pt-12 {
  padding-top: 12px !important; }

.pb-12 {
  padding-bottom: 12px !important; }

.plr-12 {
  padding-left: 12px !important;
  padding-right: 12px !important; }

.prl-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important; }

.ptb-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important; }

.pbt-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important; }

.ma-12 {
  margin: 12px !important; }

.mr-12 {
  margin-right: 12px !important; }

.mt-12 {
  margin-top: 12px !important; }

.mb-12 {
  margin-bottom: 12px !important; }

.ml-12 {
  margin-left: 12px !important; }

.mlr-12 {
  margin-left: 12px !important;
  margin-right: 12px !important; }

.mrl-12 {
  margin-left: 12px !important;
  margin-right: 12px !important; }

.mtb-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important; }

.mbt-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important; }

.border-radius-13 {
  border-radius: 13px !important; }

.fs-13 {
  font-size: 13px !important; }
  .fs-13.light-text {
    font-family: "montserrat-light"; }
  .fs-13.regular-text {
    font-family: "montserrat-regular"; }
  .fs-13.medium-text {
    font-family: "montserrat-medium"; }
  .fs-13.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-13.bold-text {
    font-family: "montserrat-bold"; }

.pa-13 {
  padding: 13px !important; }

.pr-13 {
  padding-right: 13px !important; }

.pl-13 {
  padding-left: 13px !important; }

.pt-13 {
  padding-top: 13px !important; }

.pb-13 {
  padding-bottom: 13px !important; }

.plr-13 {
  padding-left: 13px !important;
  padding-right: 13px !important; }

.prl-13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important; }

.ptb-13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important; }

.pbt-13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important; }

.ma-13 {
  margin: 13px !important; }

.mr-13 {
  margin-right: 13px !important; }

.mt-13 {
  margin-top: 13px !important; }

.mb-13 {
  margin-bottom: 13px !important; }

.ml-13 {
  margin-left: 13px !important; }

.mlr-13 {
  margin-left: 13px !important;
  margin-right: 13px !important; }

.mrl-13 {
  margin-left: 13px !important;
  margin-right: 13px !important; }

.mtb-13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important; }

.mbt-13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important; }

.border-radius-14 {
  border-radius: 14px !important; }

.fs-14 {
  font-size: 14px !important; }
  .fs-14.light-text {
    font-family: "montserrat-light"; }
  .fs-14.regular-text {
    font-family: "montserrat-regular"; }
  .fs-14.medium-text {
    font-family: "montserrat-medium"; }
  .fs-14.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-14.bold-text {
    font-family: "montserrat-bold"; }

.pa-14 {
  padding: 14px !important; }

.pr-14 {
  padding-right: 14px !important; }

.pl-14 {
  padding-left: 14px !important; }

.pt-14 {
  padding-top: 14px !important; }

.pb-14 {
  padding-bottom: 14px !important; }

.plr-14 {
  padding-left: 14px !important;
  padding-right: 14px !important; }

.prl-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important; }

.ptb-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important; }

.pbt-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important; }

.ma-14 {
  margin: 14px !important; }

.mr-14 {
  margin-right: 14px !important; }

.mt-14 {
  margin-top: 14px !important; }

.mb-14 {
  margin-bottom: 14px !important; }

.ml-14 {
  margin-left: 14px !important; }

.mlr-14 {
  margin-left: 14px !important;
  margin-right: 14px !important; }

.mrl-14 {
  margin-left: 14px !important;
  margin-right: 14px !important; }

.mtb-14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important; }

.mbt-14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important; }

.border-radius-15 {
  border-radius: 15px !important; }

.fs-15 {
  font-size: 15px !important; }
  .fs-15.light-text {
    font-family: "montserrat-light"; }
  .fs-15.regular-text {
    font-family: "montserrat-regular"; }
  .fs-15.medium-text {
    font-family: "montserrat-medium"; }
  .fs-15.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-15.bold-text {
    font-family: "montserrat-bold"; }

.pa-15 {
  padding: 15px !important; }

.pr-15 {
  padding-right: 15px !important; }

.pl-15 {
  padding-left: 15px !important; }

.pt-15 {
  padding-top: 15px !important; }

.pb-15 {
  padding-bottom: 15px !important; }

.plr-15 {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.prl-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.ptb-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pbt-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.ma-15 {
  margin: 15px !important; }

.mr-15 {
  margin-right: 15px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.ml-15 {
  margin-left: 15px !important; }

.mlr-15 {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.mrl-15 {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.mtb-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.mbt-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.border-radius-16 {
  border-radius: 16px !important; }

.fs-16 {
  font-size: 16px !important; }
  .fs-16.light-text {
    font-family: "montserrat-light"; }
  .fs-16.regular-text {
    font-family: "montserrat-regular"; }
  .fs-16.medium-text {
    font-family: "montserrat-medium"; }
  .fs-16.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-16.bold-text {
    font-family: "montserrat-bold"; }

.pa-16 {
  padding: 16px !important; }

.pr-16 {
  padding-right: 16px !important; }

.pl-16 {
  padding-left: 16px !important; }

.pt-16 {
  padding-top: 16px !important; }

.pb-16 {
  padding-bottom: 16px !important; }

.plr-16 {
  padding-left: 16px !important;
  padding-right: 16px !important; }

.prl-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important; }

.ptb-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important; }

.pbt-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important; }

.ma-16 {
  margin: 16px !important; }

.mr-16 {
  margin-right: 16px !important; }

.mt-16 {
  margin-top: 16px !important; }

.mb-16 {
  margin-bottom: 16px !important; }

.ml-16 {
  margin-left: 16px !important; }

.mlr-16 {
  margin-left: 16px !important;
  margin-right: 16px !important; }

.mrl-16 {
  margin-left: 16px !important;
  margin-right: 16px !important; }

.mtb-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important; }

.mbt-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important; }

.border-radius-17 {
  border-radius: 17px !important; }

.fs-17 {
  font-size: 17px !important; }
  .fs-17.light-text {
    font-family: "montserrat-light"; }
  .fs-17.regular-text {
    font-family: "montserrat-regular"; }
  .fs-17.medium-text {
    font-family: "montserrat-medium"; }
  .fs-17.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-17.bold-text {
    font-family: "montserrat-bold"; }

.pa-17 {
  padding: 17px !important; }

.pr-17 {
  padding-right: 17px !important; }

.pl-17 {
  padding-left: 17px !important; }

.pt-17 {
  padding-top: 17px !important; }

.pb-17 {
  padding-bottom: 17px !important; }

.plr-17 {
  padding-left: 17px !important;
  padding-right: 17px !important; }

.prl-17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important; }

.ptb-17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important; }

.pbt-17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important; }

.ma-17 {
  margin: 17px !important; }

.mr-17 {
  margin-right: 17px !important; }

.mt-17 {
  margin-top: 17px !important; }

.mb-17 {
  margin-bottom: 17px !important; }

.ml-17 {
  margin-left: 17px !important; }

.mlr-17 {
  margin-left: 17px !important;
  margin-right: 17px !important; }

.mrl-17 {
  margin-left: 17px !important;
  margin-right: 17px !important; }

.mtb-17 {
  margin-top: 17px !important;
  margin-bottom: 17px !important; }

.mbt-17 {
  margin-top: 17px !important;
  margin-bottom: 17px !important; }

.border-radius-18 {
  border-radius: 18px !important; }

.fs-18 {
  font-size: 18px !important; }
  .fs-18.light-text {
    font-family: "montserrat-light"; }
  .fs-18.regular-text {
    font-family: "montserrat-regular"; }
  .fs-18.medium-text {
    font-family: "montserrat-medium"; }
  .fs-18.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-18.bold-text {
    font-family: "montserrat-bold"; }

.pa-18 {
  padding: 18px !important; }

.pr-18 {
  padding-right: 18px !important; }

.pl-18 {
  padding-left: 18px !important; }

.pt-18 {
  padding-top: 18px !important; }

.pb-18 {
  padding-bottom: 18px !important; }

.plr-18 {
  padding-left: 18px !important;
  padding-right: 18px !important; }

.prl-18 {
  padding-top: 18px !important;
  padding-bottom: 18px !important; }

.ptb-18 {
  padding-top: 18px !important;
  padding-bottom: 18px !important; }

.pbt-18 {
  padding-top: 18px !important;
  padding-bottom: 18px !important; }

.ma-18 {
  margin: 18px !important; }

.mr-18 {
  margin-right: 18px !important; }

.mt-18 {
  margin-top: 18px !important; }

.mb-18 {
  margin-bottom: 18px !important; }

.ml-18 {
  margin-left: 18px !important; }

.mlr-18 {
  margin-left: 18px !important;
  margin-right: 18px !important; }

.mrl-18 {
  margin-left: 18px !important;
  margin-right: 18px !important; }

.mtb-18 {
  margin-top: 18px !important;
  margin-bottom: 18px !important; }

.mbt-18 {
  margin-top: 18px !important;
  margin-bottom: 18px !important; }

.border-radius-19 {
  border-radius: 19px !important; }

.fs-19 {
  font-size: 19px !important; }
  .fs-19.light-text {
    font-family: "montserrat-light"; }
  .fs-19.regular-text {
    font-family: "montserrat-regular"; }
  .fs-19.medium-text {
    font-family: "montserrat-medium"; }
  .fs-19.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-19.bold-text {
    font-family: "montserrat-bold"; }

.pa-19 {
  padding: 19px !important; }

.pr-19 {
  padding-right: 19px !important; }

.pl-19 {
  padding-left: 19px !important; }

.pt-19 {
  padding-top: 19px !important; }

.pb-19 {
  padding-bottom: 19px !important; }

.plr-19 {
  padding-left: 19px !important;
  padding-right: 19px !important; }

.prl-19 {
  padding-top: 19px !important;
  padding-bottom: 19px !important; }

.ptb-19 {
  padding-top: 19px !important;
  padding-bottom: 19px !important; }

.pbt-19 {
  padding-top: 19px !important;
  padding-bottom: 19px !important; }

.ma-19 {
  margin: 19px !important; }

.mr-19 {
  margin-right: 19px !important; }

.mt-19 {
  margin-top: 19px !important; }

.mb-19 {
  margin-bottom: 19px !important; }

.ml-19 {
  margin-left: 19px !important; }

.mlr-19 {
  margin-left: 19px !important;
  margin-right: 19px !important; }

.mrl-19 {
  margin-left: 19px !important;
  margin-right: 19px !important; }

.mtb-19 {
  margin-top: 19px !important;
  margin-bottom: 19px !important; }

.mbt-19 {
  margin-top: 19px !important;
  margin-bottom: 19px !important; }

.border-radius-20 {
  border-radius: 20px !important; }

.fs-20 {
  font-size: 20px !important; }
  .fs-20.light-text {
    font-family: "montserrat-light"; }
  .fs-20.regular-text {
    font-family: "montserrat-regular"; }
  .fs-20.medium-text {
    font-family: "montserrat-medium"; }
  .fs-20.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-20.bold-text {
    font-family: "montserrat-bold"; }

.pa-20 {
  padding: 20px !important; }

.pr-20 {
  padding-right: 20px !important; }

.pl-20 {
  padding-left: 20px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.plr-20 {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.prl-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.ptb-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.pbt-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.ma-20 {
  margin: 20px !important; }

.mr-20 {
  margin-right: 20px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.ml-20 {
  margin-left: 20px !important; }

.mlr-20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.mrl-20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.mtb-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.mbt-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.border-radius-21 {
  border-radius: 21px !important; }

.fs-21 {
  font-size: 21px !important; }
  .fs-21.light-text {
    font-family: "montserrat-light"; }
  .fs-21.regular-text {
    font-family: "montserrat-regular"; }
  .fs-21.medium-text {
    font-family: "montserrat-medium"; }
  .fs-21.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-21.bold-text {
    font-family: "montserrat-bold"; }

.pa-21 {
  padding: 21px !important; }

.pr-21 {
  padding-right: 21px !important; }

.pl-21 {
  padding-left: 21px !important; }

.pt-21 {
  padding-top: 21px !important; }

.pb-21 {
  padding-bottom: 21px !important; }

.plr-21 {
  padding-left: 21px !important;
  padding-right: 21px !important; }

.prl-21 {
  padding-top: 21px !important;
  padding-bottom: 21px !important; }

.ptb-21 {
  padding-top: 21px !important;
  padding-bottom: 21px !important; }

.pbt-21 {
  padding-top: 21px !important;
  padding-bottom: 21px !important; }

.ma-21 {
  margin: 21px !important; }

.mr-21 {
  margin-right: 21px !important; }

.mt-21 {
  margin-top: 21px !important; }

.mb-21 {
  margin-bottom: 21px !important; }

.ml-21 {
  margin-left: 21px !important; }

.mlr-21 {
  margin-left: 21px !important;
  margin-right: 21px !important; }

.mrl-21 {
  margin-left: 21px !important;
  margin-right: 21px !important; }

.mtb-21 {
  margin-top: 21px !important;
  margin-bottom: 21px !important; }

.mbt-21 {
  margin-top: 21px !important;
  margin-bottom: 21px !important; }

.border-radius-22 {
  border-radius: 22px !important; }

.fs-22 {
  font-size: 22px !important; }
  .fs-22.light-text {
    font-family: "montserrat-light"; }
  .fs-22.regular-text {
    font-family: "montserrat-regular"; }
  .fs-22.medium-text {
    font-family: "montserrat-medium"; }
  .fs-22.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-22.bold-text {
    font-family: "montserrat-bold"; }

.pa-22 {
  padding: 22px !important; }

.pr-22 {
  padding-right: 22px !important; }

.pl-22 {
  padding-left: 22px !important; }

.pt-22 {
  padding-top: 22px !important; }

.pb-22 {
  padding-bottom: 22px !important; }

.plr-22 {
  padding-left: 22px !important;
  padding-right: 22px !important; }

.prl-22 {
  padding-top: 22px !important;
  padding-bottom: 22px !important; }

.ptb-22 {
  padding-top: 22px !important;
  padding-bottom: 22px !important; }

.pbt-22 {
  padding-top: 22px !important;
  padding-bottom: 22px !important; }

.ma-22 {
  margin: 22px !important; }

.mr-22 {
  margin-right: 22px !important; }

.mt-22 {
  margin-top: 22px !important; }

.mb-22 {
  margin-bottom: 22px !important; }

.ml-22 {
  margin-left: 22px !important; }

.mlr-22 {
  margin-left: 22px !important;
  margin-right: 22px !important; }

.mrl-22 {
  margin-left: 22px !important;
  margin-right: 22px !important; }

.mtb-22 {
  margin-top: 22px !important;
  margin-bottom: 22px !important; }

.mbt-22 {
  margin-top: 22px !important;
  margin-bottom: 22px !important; }

.border-radius-23 {
  border-radius: 23px !important; }

.fs-23 {
  font-size: 23px !important; }
  .fs-23.light-text {
    font-family: "montserrat-light"; }
  .fs-23.regular-text {
    font-family: "montserrat-regular"; }
  .fs-23.medium-text {
    font-family: "montserrat-medium"; }
  .fs-23.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-23.bold-text {
    font-family: "montserrat-bold"; }

.pa-23 {
  padding: 23px !important; }

.pr-23 {
  padding-right: 23px !important; }

.pl-23 {
  padding-left: 23px !important; }

.pt-23 {
  padding-top: 23px !important; }

.pb-23 {
  padding-bottom: 23px !important; }

.plr-23 {
  padding-left: 23px !important;
  padding-right: 23px !important; }

.prl-23 {
  padding-top: 23px !important;
  padding-bottom: 23px !important; }

.ptb-23 {
  padding-top: 23px !important;
  padding-bottom: 23px !important; }

.pbt-23 {
  padding-top: 23px !important;
  padding-bottom: 23px !important; }

.ma-23 {
  margin: 23px !important; }

.mr-23 {
  margin-right: 23px !important; }

.mt-23 {
  margin-top: 23px !important; }

.mb-23 {
  margin-bottom: 23px !important; }

.ml-23 {
  margin-left: 23px !important; }

.mlr-23 {
  margin-left: 23px !important;
  margin-right: 23px !important; }

.mrl-23 {
  margin-left: 23px !important;
  margin-right: 23px !important; }

.mtb-23 {
  margin-top: 23px !important;
  margin-bottom: 23px !important; }

.mbt-23 {
  margin-top: 23px !important;
  margin-bottom: 23px !important; }

.border-radius-24 {
  border-radius: 24px !important; }

.fs-24 {
  font-size: 24px !important; }
  .fs-24.light-text {
    font-family: "montserrat-light"; }
  .fs-24.regular-text {
    font-family: "montserrat-regular"; }
  .fs-24.medium-text {
    font-family: "montserrat-medium"; }
  .fs-24.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-24.bold-text {
    font-family: "montserrat-bold"; }

.pa-24 {
  padding: 24px !important; }

.pr-24 {
  padding-right: 24px !important; }

.pl-24 {
  padding-left: 24px !important; }

.pt-24 {
  padding-top: 24px !important; }

.pb-24 {
  padding-bottom: 24px !important; }

.plr-24 {
  padding-left: 24px !important;
  padding-right: 24px !important; }

.prl-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }

.ptb-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }

.pbt-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }

.ma-24 {
  margin: 24px !important; }

.mr-24 {
  margin-right: 24px !important; }

.mt-24 {
  margin-top: 24px !important; }

.mb-24 {
  margin-bottom: 24px !important; }

.ml-24 {
  margin-left: 24px !important; }

.mlr-24 {
  margin-left: 24px !important;
  margin-right: 24px !important; }

.mrl-24 {
  margin-left: 24px !important;
  margin-right: 24px !important; }

.mtb-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important; }

.mbt-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important; }

.border-radius-25 {
  border-radius: 25px !important; }

.fs-25 {
  font-size: 25px !important; }
  .fs-25.light-text {
    font-family: "montserrat-light"; }
  .fs-25.regular-text {
    font-family: "montserrat-regular"; }
  .fs-25.medium-text {
    font-family: "montserrat-medium"; }
  .fs-25.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-25.bold-text {
    font-family: "montserrat-bold"; }

.pa-25 {
  padding: 25px !important; }

.pr-25 {
  padding-right: 25px !important; }

.pl-25 {
  padding-left: 25px !important; }

.pt-25 {
  padding-top: 25px !important; }

.pb-25 {
  padding-bottom: 25px !important; }

.plr-25 {
  padding-left: 25px !important;
  padding-right: 25px !important; }

.prl-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important; }

.ptb-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important; }

.pbt-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important; }

.ma-25 {
  margin: 25px !important; }

.mr-25 {
  margin-right: 25px !important; }

.mt-25 {
  margin-top: 25px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.ml-25 {
  margin-left: 25px !important; }

.mlr-25 {
  margin-left: 25px !important;
  margin-right: 25px !important; }

.mrl-25 {
  margin-left: 25px !important;
  margin-right: 25px !important; }

.mtb-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important; }

.mbt-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important; }

.border-radius-26 {
  border-radius: 26px !important; }

.fs-26 {
  font-size: 26px !important; }
  .fs-26.light-text {
    font-family: "montserrat-light"; }
  .fs-26.regular-text {
    font-family: "montserrat-regular"; }
  .fs-26.medium-text {
    font-family: "montserrat-medium"; }
  .fs-26.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-26.bold-text {
    font-family: "montserrat-bold"; }

.pa-26 {
  padding: 26px !important; }

.pr-26 {
  padding-right: 26px !important; }

.pl-26 {
  padding-left: 26px !important; }

.pt-26 {
  padding-top: 26px !important; }

.pb-26 {
  padding-bottom: 26px !important; }

.plr-26 {
  padding-left: 26px !important;
  padding-right: 26px !important; }

.prl-26 {
  padding-top: 26px !important;
  padding-bottom: 26px !important; }

.ptb-26 {
  padding-top: 26px !important;
  padding-bottom: 26px !important; }

.pbt-26 {
  padding-top: 26px !important;
  padding-bottom: 26px !important; }

.ma-26 {
  margin: 26px !important; }

.mr-26 {
  margin-right: 26px !important; }

.mt-26 {
  margin-top: 26px !important; }

.mb-26 {
  margin-bottom: 26px !important; }

.ml-26 {
  margin-left: 26px !important; }

.mlr-26 {
  margin-left: 26px !important;
  margin-right: 26px !important; }

.mrl-26 {
  margin-left: 26px !important;
  margin-right: 26px !important; }

.mtb-26 {
  margin-top: 26px !important;
  margin-bottom: 26px !important; }

.mbt-26 {
  margin-top: 26px !important;
  margin-bottom: 26px !important; }

.border-radius-27 {
  border-radius: 27px !important; }

.fs-27 {
  font-size: 27px !important; }
  .fs-27.light-text {
    font-family: "montserrat-light"; }
  .fs-27.regular-text {
    font-family: "montserrat-regular"; }
  .fs-27.medium-text {
    font-family: "montserrat-medium"; }
  .fs-27.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-27.bold-text {
    font-family: "montserrat-bold"; }

.pa-27 {
  padding: 27px !important; }

.pr-27 {
  padding-right: 27px !important; }

.pl-27 {
  padding-left: 27px !important; }

.pt-27 {
  padding-top: 27px !important; }

.pb-27 {
  padding-bottom: 27px !important; }

.plr-27 {
  padding-left: 27px !important;
  padding-right: 27px !important; }

.prl-27 {
  padding-top: 27px !important;
  padding-bottom: 27px !important; }

.ptb-27 {
  padding-top: 27px !important;
  padding-bottom: 27px !important; }

.pbt-27 {
  padding-top: 27px !important;
  padding-bottom: 27px !important; }

.ma-27 {
  margin: 27px !important; }

.mr-27 {
  margin-right: 27px !important; }

.mt-27 {
  margin-top: 27px !important; }

.mb-27 {
  margin-bottom: 27px !important; }

.ml-27 {
  margin-left: 27px !important; }

.mlr-27 {
  margin-left: 27px !important;
  margin-right: 27px !important; }

.mrl-27 {
  margin-left: 27px !important;
  margin-right: 27px !important; }

.mtb-27 {
  margin-top: 27px !important;
  margin-bottom: 27px !important; }

.mbt-27 {
  margin-top: 27px !important;
  margin-bottom: 27px !important; }

.border-radius-28 {
  border-radius: 28px !important; }

.fs-28 {
  font-size: 28px !important; }
  .fs-28.light-text {
    font-family: "montserrat-light"; }
  .fs-28.regular-text {
    font-family: "montserrat-regular"; }
  .fs-28.medium-text {
    font-family: "montserrat-medium"; }
  .fs-28.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-28.bold-text {
    font-family: "montserrat-bold"; }

.pa-28 {
  padding: 28px !important; }

.pr-28 {
  padding-right: 28px !important; }

.pl-28 {
  padding-left: 28px !important; }

.pt-28 {
  padding-top: 28px !important; }

.pb-28 {
  padding-bottom: 28px !important; }

.plr-28 {
  padding-left: 28px !important;
  padding-right: 28px !important; }

.prl-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important; }

.ptb-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important; }

.pbt-28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important; }

.ma-28 {
  margin: 28px !important; }

.mr-28 {
  margin-right: 28px !important; }

.mt-28 {
  margin-top: 28px !important; }

.mb-28 {
  margin-bottom: 28px !important; }

.ml-28 {
  margin-left: 28px !important; }

.mlr-28 {
  margin-left: 28px !important;
  margin-right: 28px !important; }

.mrl-28 {
  margin-left: 28px !important;
  margin-right: 28px !important; }

.mtb-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important; }

.mbt-28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important; }

.border-radius-29 {
  border-radius: 29px !important; }

.fs-29 {
  font-size: 29px !important; }
  .fs-29.light-text {
    font-family: "montserrat-light"; }
  .fs-29.regular-text {
    font-family: "montserrat-regular"; }
  .fs-29.medium-text {
    font-family: "montserrat-medium"; }
  .fs-29.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-29.bold-text {
    font-family: "montserrat-bold"; }

.pa-29 {
  padding: 29px !important; }

.pr-29 {
  padding-right: 29px !important; }

.pl-29 {
  padding-left: 29px !important; }

.pt-29 {
  padding-top: 29px !important; }

.pb-29 {
  padding-bottom: 29px !important; }

.plr-29 {
  padding-left: 29px !important;
  padding-right: 29px !important; }

.prl-29 {
  padding-top: 29px !important;
  padding-bottom: 29px !important; }

.ptb-29 {
  padding-top: 29px !important;
  padding-bottom: 29px !important; }

.pbt-29 {
  padding-top: 29px !important;
  padding-bottom: 29px !important; }

.ma-29 {
  margin: 29px !important; }

.mr-29 {
  margin-right: 29px !important; }

.mt-29 {
  margin-top: 29px !important; }

.mb-29 {
  margin-bottom: 29px !important; }

.ml-29 {
  margin-left: 29px !important; }

.mlr-29 {
  margin-left: 29px !important;
  margin-right: 29px !important; }

.mrl-29 {
  margin-left: 29px !important;
  margin-right: 29px !important; }

.mtb-29 {
  margin-top: 29px !important;
  margin-bottom: 29px !important; }

.mbt-29 {
  margin-top: 29px !important;
  margin-bottom: 29px !important; }

.border-radius-30 {
  border-radius: 30px !important; }

.fs-30 {
  font-size: 30px !important; }
  .fs-30.light-text {
    font-family: "montserrat-light"; }
  .fs-30.regular-text {
    font-family: "montserrat-regular"; }
  .fs-30.medium-text {
    font-family: "montserrat-medium"; }
  .fs-30.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-30.bold-text {
    font-family: "montserrat-bold"; }

.pa-30 {
  padding: 30px !important; }

.pr-30 {
  padding-right: 30px !important; }

.pl-30 {
  padding-left: 30px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.plr-30 {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.prl-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.ptb-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.pbt-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.ma-30 {
  margin: 30px !important; }

.mr-30 {
  margin-right: 30px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.ml-30 {
  margin-left: 30px !important; }

.mlr-30 {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.mrl-30 {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.mtb-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.mbt-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.border-radius-31 {
  border-radius: 31px !important; }

.fs-31 {
  font-size: 31px !important; }
  .fs-31.light-text {
    font-family: "montserrat-light"; }
  .fs-31.regular-text {
    font-family: "montserrat-regular"; }
  .fs-31.medium-text {
    font-family: "montserrat-medium"; }
  .fs-31.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-31.bold-text {
    font-family: "montserrat-bold"; }

.pa-31 {
  padding: 31px !important; }

.pr-31 {
  padding-right: 31px !important; }

.pl-31 {
  padding-left: 31px !important; }

.pt-31 {
  padding-top: 31px !important; }

.pb-31 {
  padding-bottom: 31px !important; }

.plr-31 {
  padding-left: 31px !important;
  padding-right: 31px !important; }

.prl-31 {
  padding-top: 31px !important;
  padding-bottom: 31px !important; }

.ptb-31 {
  padding-top: 31px !important;
  padding-bottom: 31px !important; }

.pbt-31 {
  padding-top: 31px !important;
  padding-bottom: 31px !important; }

.ma-31 {
  margin: 31px !important; }

.mr-31 {
  margin-right: 31px !important; }

.mt-31 {
  margin-top: 31px !important; }

.mb-31 {
  margin-bottom: 31px !important; }

.ml-31 {
  margin-left: 31px !important; }

.mlr-31 {
  margin-left: 31px !important;
  margin-right: 31px !important; }

.mrl-31 {
  margin-left: 31px !important;
  margin-right: 31px !important; }

.mtb-31 {
  margin-top: 31px !important;
  margin-bottom: 31px !important; }

.mbt-31 {
  margin-top: 31px !important;
  margin-bottom: 31px !important; }

.border-radius-32 {
  border-radius: 32px !important; }

.fs-32 {
  font-size: 32px !important; }
  .fs-32.light-text {
    font-family: "montserrat-light"; }
  .fs-32.regular-text {
    font-family: "montserrat-regular"; }
  .fs-32.medium-text {
    font-family: "montserrat-medium"; }
  .fs-32.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-32.bold-text {
    font-family: "montserrat-bold"; }

.pa-32 {
  padding: 32px !important; }

.pr-32 {
  padding-right: 32px !important; }

.pl-32 {
  padding-left: 32px !important; }

.pt-32 {
  padding-top: 32px !important; }

.pb-32 {
  padding-bottom: 32px !important; }

.plr-32 {
  padding-left: 32px !important;
  padding-right: 32px !important; }

.prl-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important; }

.ptb-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important; }

.pbt-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important; }

.ma-32 {
  margin: 32px !important; }

.mr-32 {
  margin-right: 32px !important; }

.mt-32 {
  margin-top: 32px !important; }

.mb-32 {
  margin-bottom: 32px !important; }

.ml-32 {
  margin-left: 32px !important; }

.mlr-32 {
  margin-left: 32px !important;
  margin-right: 32px !important; }

.mrl-32 {
  margin-left: 32px !important;
  margin-right: 32px !important; }

.mtb-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important; }

.mbt-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important; }

.border-radius-33 {
  border-radius: 33px !important; }

.fs-33 {
  font-size: 33px !important; }
  .fs-33.light-text {
    font-family: "montserrat-light"; }
  .fs-33.regular-text {
    font-family: "montserrat-regular"; }
  .fs-33.medium-text {
    font-family: "montserrat-medium"; }
  .fs-33.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-33.bold-text {
    font-family: "montserrat-bold"; }

.pa-33 {
  padding: 33px !important; }

.pr-33 {
  padding-right: 33px !important; }

.pl-33 {
  padding-left: 33px !important; }

.pt-33 {
  padding-top: 33px !important; }

.pb-33 {
  padding-bottom: 33px !important; }

.plr-33 {
  padding-left: 33px !important;
  padding-right: 33px !important; }

.prl-33 {
  padding-top: 33px !important;
  padding-bottom: 33px !important; }

.ptb-33 {
  padding-top: 33px !important;
  padding-bottom: 33px !important; }

.pbt-33 {
  padding-top: 33px !important;
  padding-bottom: 33px !important; }

.ma-33 {
  margin: 33px !important; }

.mr-33 {
  margin-right: 33px !important; }

.mt-33 {
  margin-top: 33px !important; }

.mb-33 {
  margin-bottom: 33px !important; }

.ml-33 {
  margin-left: 33px !important; }

.mlr-33 {
  margin-left: 33px !important;
  margin-right: 33px !important; }

.mrl-33 {
  margin-left: 33px !important;
  margin-right: 33px !important; }

.mtb-33 {
  margin-top: 33px !important;
  margin-bottom: 33px !important; }

.mbt-33 {
  margin-top: 33px !important;
  margin-bottom: 33px !important; }

.border-radius-34 {
  border-radius: 34px !important; }

.fs-34 {
  font-size: 34px !important; }
  .fs-34.light-text {
    font-family: "montserrat-light"; }
  .fs-34.regular-text {
    font-family: "montserrat-regular"; }
  .fs-34.medium-text {
    font-family: "montserrat-medium"; }
  .fs-34.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-34.bold-text {
    font-family: "montserrat-bold"; }

.pa-34 {
  padding: 34px !important; }

.pr-34 {
  padding-right: 34px !important; }

.pl-34 {
  padding-left: 34px !important; }

.pt-34 {
  padding-top: 34px !important; }

.pb-34 {
  padding-bottom: 34px !important; }

.plr-34 {
  padding-left: 34px !important;
  padding-right: 34px !important; }

.prl-34 {
  padding-top: 34px !important;
  padding-bottom: 34px !important; }

.ptb-34 {
  padding-top: 34px !important;
  padding-bottom: 34px !important; }

.pbt-34 {
  padding-top: 34px !important;
  padding-bottom: 34px !important; }

.ma-34 {
  margin: 34px !important; }

.mr-34 {
  margin-right: 34px !important; }

.mt-34 {
  margin-top: 34px !important; }

.mb-34 {
  margin-bottom: 34px !important; }

.ml-34 {
  margin-left: 34px !important; }

.mlr-34 {
  margin-left: 34px !important;
  margin-right: 34px !important; }

.mrl-34 {
  margin-left: 34px !important;
  margin-right: 34px !important; }

.mtb-34 {
  margin-top: 34px !important;
  margin-bottom: 34px !important; }

.mbt-34 {
  margin-top: 34px !important;
  margin-bottom: 34px !important; }

.border-radius-35 {
  border-radius: 35px !important; }

.fs-35 {
  font-size: 35px !important; }
  .fs-35.light-text {
    font-family: "montserrat-light"; }
  .fs-35.regular-text {
    font-family: "montserrat-regular"; }
  .fs-35.medium-text {
    font-family: "montserrat-medium"; }
  .fs-35.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-35.bold-text {
    font-family: "montserrat-bold"; }

.pa-35 {
  padding: 35px !important; }

.pr-35 {
  padding-right: 35px !important; }

.pl-35 {
  padding-left: 35px !important; }

.pt-35 {
  padding-top: 35px !important; }

.pb-35 {
  padding-bottom: 35px !important; }

.plr-35 {
  padding-left: 35px !important;
  padding-right: 35px !important; }

.prl-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important; }

.ptb-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important; }

.pbt-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important; }

.ma-35 {
  margin: 35px !important; }

.mr-35 {
  margin-right: 35px !important; }

.mt-35 {
  margin-top: 35px !important; }

.mb-35 {
  margin-bottom: 35px !important; }

.ml-35 {
  margin-left: 35px !important; }

.mlr-35 {
  margin-left: 35px !important;
  margin-right: 35px !important; }

.mrl-35 {
  margin-left: 35px !important;
  margin-right: 35px !important; }

.mtb-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important; }

.mbt-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important; }

.border-radius-36 {
  border-radius: 36px !important; }

.fs-36 {
  font-size: 36px !important; }
  .fs-36.light-text {
    font-family: "montserrat-light"; }
  .fs-36.regular-text {
    font-family: "montserrat-regular"; }
  .fs-36.medium-text {
    font-family: "montserrat-medium"; }
  .fs-36.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-36.bold-text {
    font-family: "montserrat-bold"; }

.pa-36 {
  padding: 36px !important; }

.pr-36 {
  padding-right: 36px !important; }

.pl-36 {
  padding-left: 36px !important; }

.pt-36 {
  padding-top: 36px !important; }

.pb-36 {
  padding-bottom: 36px !important; }

.plr-36 {
  padding-left: 36px !important;
  padding-right: 36px !important; }

.prl-36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important; }

.ptb-36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important; }

.pbt-36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important; }

.ma-36 {
  margin: 36px !important; }

.mr-36 {
  margin-right: 36px !important; }

.mt-36 {
  margin-top: 36px !important; }

.mb-36 {
  margin-bottom: 36px !important; }

.ml-36 {
  margin-left: 36px !important; }

.mlr-36 {
  margin-left: 36px !important;
  margin-right: 36px !important; }

.mrl-36 {
  margin-left: 36px !important;
  margin-right: 36px !important; }

.mtb-36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important; }

.mbt-36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important; }

.border-radius-37 {
  border-radius: 37px !important; }

.fs-37 {
  font-size: 37px !important; }
  .fs-37.light-text {
    font-family: "montserrat-light"; }
  .fs-37.regular-text {
    font-family: "montserrat-regular"; }
  .fs-37.medium-text {
    font-family: "montserrat-medium"; }
  .fs-37.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-37.bold-text {
    font-family: "montserrat-bold"; }

.pa-37 {
  padding: 37px !important; }

.pr-37 {
  padding-right: 37px !important; }

.pl-37 {
  padding-left: 37px !important; }

.pt-37 {
  padding-top: 37px !important; }

.pb-37 {
  padding-bottom: 37px !important; }

.plr-37 {
  padding-left: 37px !important;
  padding-right: 37px !important; }

.prl-37 {
  padding-top: 37px !important;
  padding-bottom: 37px !important; }

.ptb-37 {
  padding-top: 37px !important;
  padding-bottom: 37px !important; }

.pbt-37 {
  padding-top: 37px !important;
  padding-bottom: 37px !important; }

.ma-37 {
  margin: 37px !important; }

.mr-37 {
  margin-right: 37px !important; }

.mt-37 {
  margin-top: 37px !important; }

.mb-37 {
  margin-bottom: 37px !important; }

.ml-37 {
  margin-left: 37px !important; }

.mlr-37 {
  margin-left: 37px !important;
  margin-right: 37px !important; }

.mrl-37 {
  margin-left: 37px !important;
  margin-right: 37px !important; }

.mtb-37 {
  margin-top: 37px !important;
  margin-bottom: 37px !important; }

.mbt-37 {
  margin-top: 37px !important;
  margin-bottom: 37px !important; }

.border-radius-38 {
  border-radius: 38px !important; }

.fs-38 {
  font-size: 38px !important; }
  .fs-38.light-text {
    font-family: "montserrat-light"; }
  .fs-38.regular-text {
    font-family: "montserrat-regular"; }
  .fs-38.medium-text {
    font-family: "montserrat-medium"; }
  .fs-38.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-38.bold-text {
    font-family: "montserrat-bold"; }

.pa-38 {
  padding: 38px !important; }

.pr-38 {
  padding-right: 38px !important; }

.pl-38 {
  padding-left: 38px !important; }

.pt-38 {
  padding-top: 38px !important; }

.pb-38 {
  padding-bottom: 38px !important; }

.plr-38 {
  padding-left: 38px !important;
  padding-right: 38px !important; }

.prl-38 {
  padding-top: 38px !important;
  padding-bottom: 38px !important; }

.ptb-38 {
  padding-top: 38px !important;
  padding-bottom: 38px !important; }

.pbt-38 {
  padding-top: 38px !important;
  padding-bottom: 38px !important; }

.ma-38 {
  margin: 38px !important; }

.mr-38 {
  margin-right: 38px !important; }

.mt-38 {
  margin-top: 38px !important; }

.mb-38 {
  margin-bottom: 38px !important; }

.ml-38 {
  margin-left: 38px !important; }

.mlr-38 {
  margin-left: 38px !important;
  margin-right: 38px !important; }

.mrl-38 {
  margin-left: 38px !important;
  margin-right: 38px !important; }

.mtb-38 {
  margin-top: 38px !important;
  margin-bottom: 38px !important; }

.mbt-38 {
  margin-top: 38px !important;
  margin-bottom: 38px !important; }

.border-radius-39 {
  border-radius: 39px !important; }

.fs-39 {
  font-size: 39px !important; }
  .fs-39.light-text {
    font-family: "montserrat-light"; }
  .fs-39.regular-text {
    font-family: "montserrat-regular"; }
  .fs-39.medium-text {
    font-family: "montserrat-medium"; }
  .fs-39.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-39.bold-text {
    font-family: "montserrat-bold"; }

.pa-39 {
  padding: 39px !important; }

.pr-39 {
  padding-right: 39px !important; }

.pl-39 {
  padding-left: 39px !important; }

.pt-39 {
  padding-top: 39px !important; }

.pb-39 {
  padding-bottom: 39px !important; }

.plr-39 {
  padding-left: 39px !important;
  padding-right: 39px !important; }

.prl-39 {
  padding-top: 39px !important;
  padding-bottom: 39px !important; }

.ptb-39 {
  padding-top: 39px !important;
  padding-bottom: 39px !important; }

.pbt-39 {
  padding-top: 39px !important;
  padding-bottom: 39px !important; }

.ma-39 {
  margin: 39px !important; }

.mr-39 {
  margin-right: 39px !important; }

.mt-39 {
  margin-top: 39px !important; }

.mb-39 {
  margin-bottom: 39px !important; }

.ml-39 {
  margin-left: 39px !important; }

.mlr-39 {
  margin-left: 39px !important;
  margin-right: 39px !important; }

.mrl-39 {
  margin-left: 39px !important;
  margin-right: 39px !important; }

.mtb-39 {
  margin-top: 39px !important;
  margin-bottom: 39px !important; }

.mbt-39 {
  margin-top: 39px !important;
  margin-bottom: 39px !important; }

.border-radius-40 {
  border-radius: 40px !important; }

.fs-40 {
  font-size: 40px !important; }
  .fs-40.light-text {
    font-family: "montserrat-light"; }
  .fs-40.regular-text {
    font-family: "montserrat-regular"; }
  .fs-40.medium-text {
    font-family: "montserrat-medium"; }
  .fs-40.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-40.bold-text {
    font-family: "montserrat-bold"; }

.pa-40 {
  padding: 40px !important; }

.pr-40 {
  padding-right: 40px !important; }

.pl-40 {
  padding-left: 40px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.plr-40 {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.prl-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.ptb-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.pbt-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.ma-40 {
  margin: 40px !important; }

.mr-40 {
  margin-right: 40px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.ml-40 {
  margin-left: 40px !important; }

.mlr-40 {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.mrl-40 {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.mtb-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.mbt-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.border-radius-41 {
  border-radius: 41px !important; }

.fs-41 {
  font-size: 41px !important; }
  .fs-41.light-text {
    font-family: "montserrat-light"; }
  .fs-41.regular-text {
    font-family: "montserrat-regular"; }
  .fs-41.medium-text {
    font-family: "montserrat-medium"; }
  .fs-41.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-41.bold-text {
    font-family: "montserrat-bold"; }

.pa-41 {
  padding: 41px !important; }

.pr-41 {
  padding-right: 41px !important; }

.pl-41 {
  padding-left: 41px !important; }

.pt-41 {
  padding-top: 41px !important; }

.pb-41 {
  padding-bottom: 41px !important; }

.plr-41 {
  padding-left: 41px !important;
  padding-right: 41px !important; }

.prl-41 {
  padding-top: 41px !important;
  padding-bottom: 41px !important; }

.ptb-41 {
  padding-top: 41px !important;
  padding-bottom: 41px !important; }

.pbt-41 {
  padding-top: 41px !important;
  padding-bottom: 41px !important; }

.ma-41 {
  margin: 41px !important; }

.mr-41 {
  margin-right: 41px !important; }

.mt-41 {
  margin-top: 41px !important; }

.mb-41 {
  margin-bottom: 41px !important; }

.ml-41 {
  margin-left: 41px !important; }

.mlr-41 {
  margin-left: 41px !important;
  margin-right: 41px !important; }

.mrl-41 {
  margin-left: 41px !important;
  margin-right: 41px !important; }

.mtb-41 {
  margin-top: 41px !important;
  margin-bottom: 41px !important; }

.mbt-41 {
  margin-top: 41px !important;
  margin-bottom: 41px !important; }

.border-radius-42 {
  border-radius: 42px !important; }

.fs-42 {
  font-size: 42px !important; }
  .fs-42.light-text {
    font-family: "montserrat-light"; }
  .fs-42.regular-text {
    font-family: "montserrat-regular"; }
  .fs-42.medium-text {
    font-family: "montserrat-medium"; }
  .fs-42.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-42.bold-text {
    font-family: "montserrat-bold"; }

.pa-42 {
  padding: 42px !important; }

.pr-42 {
  padding-right: 42px !important; }

.pl-42 {
  padding-left: 42px !important; }

.pt-42 {
  padding-top: 42px !important; }

.pb-42 {
  padding-bottom: 42px !important; }

.plr-42 {
  padding-left: 42px !important;
  padding-right: 42px !important; }

.prl-42 {
  padding-top: 42px !important;
  padding-bottom: 42px !important; }

.ptb-42 {
  padding-top: 42px !important;
  padding-bottom: 42px !important; }

.pbt-42 {
  padding-top: 42px !important;
  padding-bottom: 42px !important; }

.ma-42 {
  margin: 42px !important; }

.mr-42 {
  margin-right: 42px !important; }

.mt-42 {
  margin-top: 42px !important; }

.mb-42 {
  margin-bottom: 42px !important; }

.ml-42 {
  margin-left: 42px !important; }

.mlr-42 {
  margin-left: 42px !important;
  margin-right: 42px !important; }

.mrl-42 {
  margin-left: 42px !important;
  margin-right: 42px !important; }

.mtb-42 {
  margin-top: 42px !important;
  margin-bottom: 42px !important; }

.mbt-42 {
  margin-top: 42px !important;
  margin-bottom: 42px !important; }

.border-radius-43 {
  border-radius: 43px !important; }

.fs-43 {
  font-size: 43px !important; }
  .fs-43.light-text {
    font-family: "montserrat-light"; }
  .fs-43.regular-text {
    font-family: "montserrat-regular"; }
  .fs-43.medium-text {
    font-family: "montserrat-medium"; }
  .fs-43.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-43.bold-text {
    font-family: "montserrat-bold"; }

.pa-43 {
  padding: 43px !important; }

.pr-43 {
  padding-right: 43px !important; }

.pl-43 {
  padding-left: 43px !important; }

.pt-43 {
  padding-top: 43px !important; }

.pb-43 {
  padding-bottom: 43px !important; }

.plr-43 {
  padding-left: 43px !important;
  padding-right: 43px !important; }

.prl-43 {
  padding-top: 43px !important;
  padding-bottom: 43px !important; }

.ptb-43 {
  padding-top: 43px !important;
  padding-bottom: 43px !important; }

.pbt-43 {
  padding-top: 43px !important;
  padding-bottom: 43px !important; }

.ma-43 {
  margin: 43px !important; }

.mr-43 {
  margin-right: 43px !important; }

.mt-43 {
  margin-top: 43px !important; }

.mb-43 {
  margin-bottom: 43px !important; }

.ml-43 {
  margin-left: 43px !important; }

.mlr-43 {
  margin-left: 43px !important;
  margin-right: 43px !important; }

.mrl-43 {
  margin-left: 43px !important;
  margin-right: 43px !important; }

.mtb-43 {
  margin-top: 43px !important;
  margin-bottom: 43px !important; }

.mbt-43 {
  margin-top: 43px !important;
  margin-bottom: 43px !important; }

.border-radius-44 {
  border-radius: 44px !important; }

.fs-44 {
  font-size: 44px !important; }
  .fs-44.light-text {
    font-family: "montserrat-light"; }
  .fs-44.regular-text {
    font-family: "montserrat-regular"; }
  .fs-44.medium-text {
    font-family: "montserrat-medium"; }
  .fs-44.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-44.bold-text {
    font-family: "montserrat-bold"; }

.pa-44 {
  padding: 44px !important; }

.pr-44 {
  padding-right: 44px !important; }

.pl-44 {
  padding-left: 44px !important; }

.pt-44 {
  padding-top: 44px !important; }

.pb-44 {
  padding-bottom: 44px !important; }

.plr-44 {
  padding-left: 44px !important;
  padding-right: 44px !important; }

.prl-44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important; }

.ptb-44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important; }

.pbt-44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important; }

.ma-44 {
  margin: 44px !important; }

.mr-44 {
  margin-right: 44px !important; }

.mt-44 {
  margin-top: 44px !important; }

.mb-44 {
  margin-bottom: 44px !important; }

.ml-44 {
  margin-left: 44px !important; }

.mlr-44 {
  margin-left: 44px !important;
  margin-right: 44px !important; }

.mrl-44 {
  margin-left: 44px !important;
  margin-right: 44px !important; }

.mtb-44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important; }

.mbt-44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important; }

.border-radius-45 {
  border-radius: 45px !important; }

.fs-45 {
  font-size: 45px !important; }
  .fs-45.light-text {
    font-family: "montserrat-light"; }
  .fs-45.regular-text {
    font-family: "montserrat-regular"; }
  .fs-45.medium-text {
    font-family: "montserrat-medium"; }
  .fs-45.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-45.bold-text {
    font-family: "montserrat-bold"; }

.pa-45 {
  padding: 45px !important; }

.pr-45 {
  padding-right: 45px !important; }

.pl-45 {
  padding-left: 45px !important; }

.pt-45 {
  padding-top: 45px !important; }

.pb-45 {
  padding-bottom: 45px !important; }

.plr-45 {
  padding-left: 45px !important;
  padding-right: 45px !important; }

.prl-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important; }

.ptb-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important; }

.pbt-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important; }

.ma-45 {
  margin: 45px !important; }

.mr-45 {
  margin-right: 45px !important; }

.mt-45 {
  margin-top: 45px !important; }

.mb-45 {
  margin-bottom: 45px !important; }

.ml-45 {
  margin-left: 45px !important; }

.mlr-45 {
  margin-left: 45px !important;
  margin-right: 45px !important; }

.mrl-45 {
  margin-left: 45px !important;
  margin-right: 45px !important; }

.mtb-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important; }

.mbt-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important; }

.border-radius-46 {
  border-radius: 46px !important; }

.fs-46 {
  font-size: 46px !important; }
  .fs-46.light-text {
    font-family: "montserrat-light"; }
  .fs-46.regular-text {
    font-family: "montserrat-regular"; }
  .fs-46.medium-text {
    font-family: "montserrat-medium"; }
  .fs-46.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-46.bold-text {
    font-family: "montserrat-bold"; }

.pa-46 {
  padding: 46px !important; }

.pr-46 {
  padding-right: 46px !important; }

.pl-46 {
  padding-left: 46px !important; }

.pt-46 {
  padding-top: 46px !important; }

.pb-46 {
  padding-bottom: 46px !important; }

.plr-46 {
  padding-left: 46px !important;
  padding-right: 46px !important; }

.prl-46 {
  padding-top: 46px !important;
  padding-bottom: 46px !important; }

.ptb-46 {
  padding-top: 46px !important;
  padding-bottom: 46px !important; }

.pbt-46 {
  padding-top: 46px !important;
  padding-bottom: 46px !important; }

.ma-46 {
  margin: 46px !important; }

.mr-46 {
  margin-right: 46px !important; }

.mt-46 {
  margin-top: 46px !important; }

.mb-46 {
  margin-bottom: 46px !important; }

.ml-46 {
  margin-left: 46px !important; }

.mlr-46 {
  margin-left: 46px !important;
  margin-right: 46px !important; }

.mrl-46 {
  margin-left: 46px !important;
  margin-right: 46px !important; }

.mtb-46 {
  margin-top: 46px !important;
  margin-bottom: 46px !important; }

.mbt-46 {
  margin-top: 46px !important;
  margin-bottom: 46px !important; }

.border-radius-47 {
  border-radius: 47px !important; }

.fs-47 {
  font-size: 47px !important; }
  .fs-47.light-text {
    font-family: "montserrat-light"; }
  .fs-47.regular-text {
    font-family: "montserrat-regular"; }
  .fs-47.medium-text {
    font-family: "montserrat-medium"; }
  .fs-47.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-47.bold-text {
    font-family: "montserrat-bold"; }

.pa-47 {
  padding: 47px !important; }

.pr-47 {
  padding-right: 47px !important; }

.pl-47 {
  padding-left: 47px !important; }

.pt-47 {
  padding-top: 47px !important; }

.pb-47 {
  padding-bottom: 47px !important; }

.plr-47 {
  padding-left: 47px !important;
  padding-right: 47px !important; }

.prl-47 {
  padding-top: 47px !important;
  padding-bottom: 47px !important; }

.ptb-47 {
  padding-top: 47px !important;
  padding-bottom: 47px !important; }

.pbt-47 {
  padding-top: 47px !important;
  padding-bottom: 47px !important; }

.ma-47 {
  margin: 47px !important; }

.mr-47 {
  margin-right: 47px !important; }

.mt-47 {
  margin-top: 47px !important; }

.mb-47 {
  margin-bottom: 47px !important; }

.ml-47 {
  margin-left: 47px !important; }

.mlr-47 {
  margin-left: 47px !important;
  margin-right: 47px !important; }

.mrl-47 {
  margin-left: 47px !important;
  margin-right: 47px !important; }

.mtb-47 {
  margin-top: 47px !important;
  margin-bottom: 47px !important; }

.mbt-47 {
  margin-top: 47px !important;
  margin-bottom: 47px !important; }

.border-radius-48 {
  border-radius: 48px !important; }

.fs-48 {
  font-size: 48px !important; }
  .fs-48.light-text {
    font-family: "montserrat-light"; }
  .fs-48.regular-text {
    font-family: "montserrat-regular"; }
  .fs-48.medium-text {
    font-family: "montserrat-medium"; }
  .fs-48.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-48.bold-text {
    font-family: "montserrat-bold"; }

.pa-48 {
  padding: 48px !important; }

.pr-48 {
  padding-right: 48px !important; }

.pl-48 {
  padding-left: 48px !important; }

.pt-48 {
  padding-top: 48px !important; }

.pb-48 {
  padding-bottom: 48px !important; }

.plr-48 {
  padding-left: 48px !important;
  padding-right: 48px !important; }

.prl-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important; }

.ptb-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important; }

.pbt-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important; }

.ma-48 {
  margin: 48px !important; }

.mr-48 {
  margin-right: 48px !important; }

.mt-48 {
  margin-top: 48px !important; }

.mb-48 {
  margin-bottom: 48px !important; }

.ml-48 {
  margin-left: 48px !important; }

.mlr-48 {
  margin-left: 48px !important;
  margin-right: 48px !important; }

.mrl-48 {
  margin-left: 48px !important;
  margin-right: 48px !important; }

.mtb-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important; }

.mbt-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important; }

.border-radius-49 {
  border-radius: 49px !important; }

.fs-49 {
  font-size: 49px !important; }
  .fs-49.light-text {
    font-family: "montserrat-light"; }
  .fs-49.regular-text {
    font-family: "montserrat-regular"; }
  .fs-49.medium-text {
    font-family: "montserrat-medium"; }
  .fs-49.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-49.bold-text {
    font-family: "montserrat-bold"; }

.pa-49 {
  padding: 49px !important; }

.pr-49 {
  padding-right: 49px !important; }

.pl-49 {
  padding-left: 49px !important; }

.pt-49 {
  padding-top: 49px !important; }

.pb-49 {
  padding-bottom: 49px !important; }

.plr-49 {
  padding-left: 49px !important;
  padding-right: 49px !important; }

.prl-49 {
  padding-top: 49px !important;
  padding-bottom: 49px !important; }

.ptb-49 {
  padding-top: 49px !important;
  padding-bottom: 49px !important; }

.pbt-49 {
  padding-top: 49px !important;
  padding-bottom: 49px !important; }

.ma-49 {
  margin: 49px !important; }

.mr-49 {
  margin-right: 49px !important; }

.mt-49 {
  margin-top: 49px !important; }

.mb-49 {
  margin-bottom: 49px !important; }

.ml-49 {
  margin-left: 49px !important; }

.mlr-49 {
  margin-left: 49px !important;
  margin-right: 49px !important; }

.mrl-49 {
  margin-left: 49px !important;
  margin-right: 49px !important; }

.mtb-49 {
  margin-top: 49px !important;
  margin-bottom: 49px !important; }

.mbt-49 {
  margin-top: 49px !important;
  margin-bottom: 49px !important; }

.border-radius-50 {
  border-radius: 50px !important; }

.fs-50 {
  font-size: 50px !important; }
  .fs-50.light-text {
    font-family: "montserrat-light"; }
  .fs-50.regular-text {
    font-family: "montserrat-regular"; }
  .fs-50.medium-text {
    font-family: "montserrat-medium"; }
  .fs-50.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-50.bold-text {
    font-family: "montserrat-bold"; }

.pa-50 {
  padding: 50px !important; }

.pr-50 {
  padding-right: 50px !important; }

.pl-50 {
  padding-left: 50px !important; }

.pt-50 {
  padding-top: 50px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.plr-50 {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.prl-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.ptb-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.pbt-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.ma-50 {
  margin: 50px !important; }

.mr-50 {
  margin-right: 50px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.ml-50 {
  margin-left: 50px !important; }

.mlr-50 {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.mrl-50 {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.mtb-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.mbt-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.border-radius-51 {
  border-radius: 51px !important; }

.fs-51 {
  font-size: 51px !important; }
  .fs-51.light-text {
    font-family: "montserrat-light"; }
  .fs-51.regular-text {
    font-family: "montserrat-regular"; }
  .fs-51.medium-text {
    font-family: "montserrat-medium"; }
  .fs-51.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-51.bold-text {
    font-family: "montserrat-bold"; }

.pa-51 {
  padding: 51px !important; }

.pr-51 {
  padding-right: 51px !important; }

.pl-51 {
  padding-left: 51px !important; }

.pt-51 {
  padding-top: 51px !important; }

.pb-51 {
  padding-bottom: 51px !important; }

.plr-51 {
  padding-left: 51px !important;
  padding-right: 51px !important; }

.prl-51 {
  padding-top: 51px !important;
  padding-bottom: 51px !important; }

.ptb-51 {
  padding-top: 51px !important;
  padding-bottom: 51px !important; }

.pbt-51 {
  padding-top: 51px !important;
  padding-bottom: 51px !important; }

.ma-51 {
  margin: 51px !important; }

.mr-51 {
  margin-right: 51px !important; }

.mt-51 {
  margin-top: 51px !important; }

.mb-51 {
  margin-bottom: 51px !important; }

.ml-51 {
  margin-left: 51px !important; }

.mlr-51 {
  margin-left: 51px !important;
  margin-right: 51px !important; }

.mrl-51 {
  margin-left: 51px !important;
  margin-right: 51px !important; }

.mtb-51 {
  margin-top: 51px !important;
  margin-bottom: 51px !important; }

.mbt-51 {
  margin-top: 51px !important;
  margin-bottom: 51px !important; }

.border-radius-52 {
  border-radius: 52px !important; }

.fs-52 {
  font-size: 52px !important; }
  .fs-52.light-text {
    font-family: "montserrat-light"; }
  .fs-52.regular-text {
    font-family: "montserrat-regular"; }
  .fs-52.medium-text {
    font-family: "montserrat-medium"; }
  .fs-52.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-52.bold-text {
    font-family: "montserrat-bold"; }

.pa-52 {
  padding: 52px !important; }

.pr-52 {
  padding-right: 52px !important; }

.pl-52 {
  padding-left: 52px !important; }

.pt-52 {
  padding-top: 52px !important; }

.pb-52 {
  padding-bottom: 52px !important; }

.plr-52 {
  padding-left: 52px !important;
  padding-right: 52px !important; }

.prl-52 {
  padding-top: 52px !important;
  padding-bottom: 52px !important; }

.ptb-52 {
  padding-top: 52px !important;
  padding-bottom: 52px !important; }

.pbt-52 {
  padding-top: 52px !important;
  padding-bottom: 52px !important; }

.ma-52 {
  margin: 52px !important; }

.mr-52 {
  margin-right: 52px !important; }

.mt-52 {
  margin-top: 52px !important; }

.mb-52 {
  margin-bottom: 52px !important; }

.ml-52 {
  margin-left: 52px !important; }

.mlr-52 {
  margin-left: 52px !important;
  margin-right: 52px !important; }

.mrl-52 {
  margin-left: 52px !important;
  margin-right: 52px !important; }

.mtb-52 {
  margin-top: 52px !important;
  margin-bottom: 52px !important; }

.mbt-52 {
  margin-top: 52px !important;
  margin-bottom: 52px !important; }

.border-radius-53 {
  border-radius: 53px !important; }

.fs-53 {
  font-size: 53px !important; }
  .fs-53.light-text {
    font-family: "montserrat-light"; }
  .fs-53.regular-text {
    font-family: "montserrat-regular"; }
  .fs-53.medium-text {
    font-family: "montserrat-medium"; }
  .fs-53.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-53.bold-text {
    font-family: "montserrat-bold"; }

.pa-53 {
  padding: 53px !important; }

.pr-53 {
  padding-right: 53px !important; }

.pl-53 {
  padding-left: 53px !important; }

.pt-53 {
  padding-top: 53px !important; }

.pb-53 {
  padding-bottom: 53px !important; }

.plr-53 {
  padding-left: 53px !important;
  padding-right: 53px !important; }

.prl-53 {
  padding-top: 53px !important;
  padding-bottom: 53px !important; }

.ptb-53 {
  padding-top: 53px !important;
  padding-bottom: 53px !important; }

.pbt-53 {
  padding-top: 53px !important;
  padding-bottom: 53px !important; }

.ma-53 {
  margin: 53px !important; }

.mr-53 {
  margin-right: 53px !important; }

.mt-53 {
  margin-top: 53px !important; }

.mb-53 {
  margin-bottom: 53px !important; }

.ml-53 {
  margin-left: 53px !important; }

.mlr-53 {
  margin-left: 53px !important;
  margin-right: 53px !important; }

.mrl-53 {
  margin-left: 53px !important;
  margin-right: 53px !important; }

.mtb-53 {
  margin-top: 53px !important;
  margin-bottom: 53px !important; }

.mbt-53 {
  margin-top: 53px !important;
  margin-bottom: 53px !important; }

.border-radius-54 {
  border-radius: 54px !important; }

.fs-54 {
  font-size: 54px !important; }
  .fs-54.light-text {
    font-family: "montserrat-light"; }
  .fs-54.regular-text {
    font-family: "montserrat-regular"; }
  .fs-54.medium-text {
    font-family: "montserrat-medium"; }
  .fs-54.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-54.bold-text {
    font-family: "montserrat-bold"; }

.pa-54 {
  padding: 54px !important; }

.pr-54 {
  padding-right: 54px !important; }

.pl-54 {
  padding-left: 54px !important; }

.pt-54 {
  padding-top: 54px !important; }

.pb-54 {
  padding-bottom: 54px !important; }

.plr-54 {
  padding-left: 54px !important;
  padding-right: 54px !important; }

.prl-54 {
  padding-top: 54px !important;
  padding-bottom: 54px !important; }

.ptb-54 {
  padding-top: 54px !important;
  padding-bottom: 54px !important; }

.pbt-54 {
  padding-top: 54px !important;
  padding-bottom: 54px !important; }

.ma-54 {
  margin: 54px !important; }

.mr-54 {
  margin-right: 54px !important; }

.mt-54 {
  margin-top: 54px !important; }

.mb-54 {
  margin-bottom: 54px !important; }

.ml-54 {
  margin-left: 54px !important; }

.mlr-54 {
  margin-left: 54px !important;
  margin-right: 54px !important; }

.mrl-54 {
  margin-left: 54px !important;
  margin-right: 54px !important; }

.mtb-54 {
  margin-top: 54px !important;
  margin-bottom: 54px !important; }

.mbt-54 {
  margin-top: 54px !important;
  margin-bottom: 54px !important; }

.border-radius-55 {
  border-radius: 55px !important; }

.fs-55 {
  font-size: 55px !important; }
  .fs-55.light-text {
    font-family: "montserrat-light"; }
  .fs-55.regular-text {
    font-family: "montserrat-regular"; }
  .fs-55.medium-text {
    font-family: "montserrat-medium"; }
  .fs-55.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-55.bold-text {
    font-family: "montserrat-bold"; }

.pa-55 {
  padding: 55px !important; }

.pr-55 {
  padding-right: 55px !important; }

.pl-55 {
  padding-left: 55px !important; }

.pt-55 {
  padding-top: 55px !important; }

.pb-55 {
  padding-bottom: 55px !important; }

.plr-55 {
  padding-left: 55px !important;
  padding-right: 55px !important; }

.prl-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important; }

.ptb-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important; }

.pbt-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important; }

.ma-55 {
  margin: 55px !important; }

.mr-55 {
  margin-right: 55px !important; }

.mt-55 {
  margin-top: 55px !important; }

.mb-55 {
  margin-bottom: 55px !important; }

.ml-55 {
  margin-left: 55px !important; }

.mlr-55 {
  margin-left: 55px !important;
  margin-right: 55px !important; }

.mrl-55 {
  margin-left: 55px !important;
  margin-right: 55px !important; }

.mtb-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important; }

.mbt-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important; }

.border-radius-56 {
  border-radius: 56px !important; }

.fs-56 {
  font-size: 56px !important; }
  .fs-56.light-text {
    font-family: "montserrat-light"; }
  .fs-56.regular-text {
    font-family: "montserrat-regular"; }
  .fs-56.medium-text {
    font-family: "montserrat-medium"; }
  .fs-56.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-56.bold-text {
    font-family: "montserrat-bold"; }

.pa-56 {
  padding: 56px !important; }

.pr-56 {
  padding-right: 56px !important; }

.pl-56 {
  padding-left: 56px !important; }

.pt-56 {
  padding-top: 56px !important; }

.pb-56 {
  padding-bottom: 56px !important; }

.plr-56 {
  padding-left: 56px !important;
  padding-right: 56px !important; }

.prl-56 {
  padding-top: 56px !important;
  padding-bottom: 56px !important; }

.ptb-56 {
  padding-top: 56px !important;
  padding-bottom: 56px !important; }

.pbt-56 {
  padding-top: 56px !important;
  padding-bottom: 56px !important; }

.ma-56 {
  margin: 56px !important; }

.mr-56 {
  margin-right: 56px !important; }

.mt-56 {
  margin-top: 56px !important; }

.mb-56 {
  margin-bottom: 56px !important; }

.ml-56 {
  margin-left: 56px !important; }

.mlr-56 {
  margin-left: 56px !important;
  margin-right: 56px !important; }

.mrl-56 {
  margin-left: 56px !important;
  margin-right: 56px !important; }

.mtb-56 {
  margin-top: 56px !important;
  margin-bottom: 56px !important; }

.mbt-56 {
  margin-top: 56px !important;
  margin-bottom: 56px !important; }

.border-radius-57 {
  border-radius: 57px !important; }

.fs-57 {
  font-size: 57px !important; }
  .fs-57.light-text {
    font-family: "montserrat-light"; }
  .fs-57.regular-text {
    font-family: "montserrat-regular"; }
  .fs-57.medium-text {
    font-family: "montserrat-medium"; }
  .fs-57.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-57.bold-text {
    font-family: "montserrat-bold"; }

.pa-57 {
  padding: 57px !important; }

.pr-57 {
  padding-right: 57px !important; }

.pl-57 {
  padding-left: 57px !important; }

.pt-57 {
  padding-top: 57px !important; }

.pb-57 {
  padding-bottom: 57px !important; }

.plr-57 {
  padding-left: 57px !important;
  padding-right: 57px !important; }

.prl-57 {
  padding-top: 57px !important;
  padding-bottom: 57px !important; }

.ptb-57 {
  padding-top: 57px !important;
  padding-bottom: 57px !important; }

.pbt-57 {
  padding-top: 57px !important;
  padding-bottom: 57px !important; }

.ma-57 {
  margin: 57px !important; }

.mr-57 {
  margin-right: 57px !important; }

.mt-57 {
  margin-top: 57px !important; }

.mb-57 {
  margin-bottom: 57px !important; }

.ml-57 {
  margin-left: 57px !important; }

.mlr-57 {
  margin-left: 57px !important;
  margin-right: 57px !important; }

.mrl-57 {
  margin-left: 57px !important;
  margin-right: 57px !important; }

.mtb-57 {
  margin-top: 57px !important;
  margin-bottom: 57px !important; }

.mbt-57 {
  margin-top: 57px !important;
  margin-bottom: 57px !important; }

.border-radius-58 {
  border-radius: 58px !important; }

.fs-58 {
  font-size: 58px !important; }
  .fs-58.light-text {
    font-family: "montserrat-light"; }
  .fs-58.regular-text {
    font-family: "montserrat-regular"; }
  .fs-58.medium-text {
    font-family: "montserrat-medium"; }
  .fs-58.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-58.bold-text {
    font-family: "montserrat-bold"; }

.pa-58 {
  padding: 58px !important; }

.pr-58 {
  padding-right: 58px !important; }

.pl-58 {
  padding-left: 58px !important; }

.pt-58 {
  padding-top: 58px !important; }

.pb-58 {
  padding-bottom: 58px !important; }

.plr-58 {
  padding-left: 58px !important;
  padding-right: 58px !important; }

.prl-58 {
  padding-top: 58px !important;
  padding-bottom: 58px !important; }

.ptb-58 {
  padding-top: 58px !important;
  padding-bottom: 58px !important; }

.pbt-58 {
  padding-top: 58px !important;
  padding-bottom: 58px !important; }

.ma-58 {
  margin: 58px !important; }

.mr-58 {
  margin-right: 58px !important; }

.mt-58 {
  margin-top: 58px !important; }

.mb-58 {
  margin-bottom: 58px !important; }

.ml-58 {
  margin-left: 58px !important; }

.mlr-58 {
  margin-left: 58px !important;
  margin-right: 58px !important; }

.mrl-58 {
  margin-left: 58px !important;
  margin-right: 58px !important; }

.mtb-58 {
  margin-top: 58px !important;
  margin-bottom: 58px !important; }

.mbt-58 {
  margin-top: 58px !important;
  margin-bottom: 58px !important; }

.border-radius-59 {
  border-radius: 59px !important; }

.fs-59 {
  font-size: 59px !important; }
  .fs-59.light-text {
    font-family: "montserrat-light"; }
  .fs-59.regular-text {
    font-family: "montserrat-regular"; }
  .fs-59.medium-text {
    font-family: "montserrat-medium"; }
  .fs-59.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-59.bold-text {
    font-family: "montserrat-bold"; }

.pa-59 {
  padding: 59px !important; }

.pr-59 {
  padding-right: 59px !important; }

.pl-59 {
  padding-left: 59px !important; }

.pt-59 {
  padding-top: 59px !important; }

.pb-59 {
  padding-bottom: 59px !important; }

.plr-59 {
  padding-left: 59px !important;
  padding-right: 59px !important; }

.prl-59 {
  padding-top: 59px !important;
  padding-bottom: 59px !important; }

.ptb-59 {
  padding-top: 59px !important;
  padding-bottom: 59px !important; }

.pbt-59 {
  padding-top: 59px !important;
  padding-bottom: 59px !important; }

.ma-59 {
  margin: 59px !important; }

.mr-59 {
  margin-right: 59px !important; }

.mt-59 {
  margin-top: 59px !important; }

.mb-59 {
  margin-bottom: 59px !important; }

.ml-59 {
  margin-left: 59px !important; }

.mlr-59 {
  margin-left: 59px !important;
  margin-right: 59px !important; }

.mrl-59 {
  margin-left: 59px !important;
  margin-right: 59px !important; }

.mtb-59 {
  margin-top: 59px !important;
  margin-bottom: 59px !important; }

.mbt-59 {
  margin-top: 59px !important;
  margin-bottom: 59px !important; }

.border-radius-60 {
  border-radius: 60px !important; }

.fs-60 {
  font-size: 60px !important; }
  .fs-60.light-text {
    font-family: "montserrat-light"; }
  .fs-60.regular-text {
    font-family: "montserrat-regular"; }
  .fs-60.medium-text {
    font-family: "montserrat-medium"; }
  .fs-60.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-60.bold-text {
    font-family: "montserrat-bold"; }

.pa-60 {
  padding: 60px !important; }

.pr-60 {
  padding-right: 60px !important; }

.pl-60 {
  padding-left: 60px !important; }

.pt-60 {
  padding-top: 60px !important; }

.pb-60 {
  padding-bottom: 60px !important; }

.plr-60 {
  padding-left: 60px !important;
  padding-right: 60px !important; }

.prl-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important; }

.ptb-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important; }

.pbt-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important; }

.ma-60 {
  margin: 60px !important; }

.mr-60 {
  margin-right: 60px !important; }

.mt-60 {
  margin-top: 60px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.ml-60 {
  margin-left: 60px !important; }

.mlr-60 {
  margin-left: 60px !important;
  margin-right: 60px !important; }

.mrl-60 {
  margin-left: 60px !important;
  margin-right: 60px !important; }

.mtb-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important; }

.mbt-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important; }

.border-radius-61 {
  border-radius: 61px !important; }

.fs-61 {
  font-size: 61px !important; }
  .fs-61.light-text {
    font-family: "montserrat-light"; }
  .fs-61.regular-text {
    font-family: "montserrat-regular"; }
  .fs-61.medium-text {
    font-family: "montserrat-medium"; }
  .fs-61.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-61.bold-text {
    font-family: "montserrat-bold"; }

.pa-61 {
  padding: 61px !important; }

.pr-61 {
  padding-right: 61px !important; }

.pl-61 {
  padding-left: 61px !important; }

.pt-61 {
  padding-top: 61px !important; }

.pb-61 {
  padding-bottom: 61px !important; }

.plr-61 {
  padding-left: 61px !important;
  padding-right: 61px !important; }

.prl-61 {
  padding-top: 61px !important;
  padding-bottom: 61px !important; }

.ptb-61 {
  padding-top: 61px !important;
  padding-bottom: 61px !important; }

.pbt-61 {
  padding-top: 61px !important;
  padding-bottom: 61px !important; }

.ma-61 {
  margin: 61px !important; }

.mr-61 {
  margin-right: 61px !important; }

.mt-61 {
  margin-top: 61px !important; }

.mb-61 {
  margin-bottom: 61px !important; }

.ml-61 {
  margin-left: 61px !important; }

.mlr-61 {
  margin-left: 61px !important;
  margin-right: 61px !important; }

.mrl-61 {
  margin-left: 61px !important;
  margin-right: 61px !important; }

.mtb-61 {
  margin-top: 61px !important;
  margin-bottom: 61px !important; }

.mbt-61 {
  margin-top: 61px !important;
  margin-bottom: 61px !important; }

.border-radius-62 {
  border-radius: 62px !important; }

.fs-62 {
  font-size: 62px !important; }
  .fs-62.light-text {
    font-family: "montserrat-light"; }
  .fs-62.regular-text {
    font-family: "montserrat-regular"; }
  .fs-62.medium-text {
    font-family: "montserrat-medium"; }
  .fs-62.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-62.bold-text {
    font-family: "montserrat-bold"; }

.pa-62 {
  padding: 62px !important; }

.pr-62 {
  padding-right: 62px !important; }

.pl-62 {
  padding-left: 62px !important; }

.pt-62 {
  padding-top: 62px !important; }

.pb-62 {
  padding-bottom: 62px !important; }

.plr-62 {
  padding-left: 62px !important;
  padding-right: 62px !important; }

.prl-62 {
  padding-top: 62px !important;
  padding-bottom: 62px !important; }

.ptb-62 {
  padding-top: 62px !important;
  padding-bottom: 62px !important; }

.pbt-62 {
  padding-top: 62px !important;
  padding-bottom: 62px !important; }

.ma-62 {
  margin: 62px !important; }

.mr-62 {
  margin-right: 62px !important; }

.mt-62 {
  margin-top: 62px !important; }

.mb-62 {
  margin-bottom: 62px !important; }

.ml-62 {
  margin-left: 62px !important; }

.mlr-62 {
  margin-left: 62px !important;
  margin-right: 62px !important; }

.mrl-62 {
  margin-left: 62px !important;
  margin-right: 62px !important; }

.mtb-62 {
  margin-top: 62px !important;
  margin-bottom: 62px !important; }

.mbt-62 {
  margin-top: 62px !important;
  margin-bottom: 62px !important; }

.border-radius-63 {
  border-radius: 63px !important; }

.fs-63 {
  font-size: 63px !important; }
  .fs-63.light-text {
    font-family: "montserrat-light"; }
  .fs-63.regular-text {
    font-family: "montserrat-regular"; }
  .fs-63.medium-text {
    font-family: "montserrat-medium"; }
  .fs-63.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-63.bold-text {
    font-family: "montserrat-bold"; }

.pa-63 {
  padding: 63px !important; }

.pr-63 {
  padding-right: 63px !important; }

.pl-63 {
  padding-left: 63px !important; }

.pt-63 {
  padding-top: 63px !important; }

.pb-63 {
  padding-bottom: 63px !important; }

.plr-63 {
  padding-left: 63px !important;
  padding-right: 63px !important; }

.prl-63 {
  padding-top: 63px !important;
  padding-bottom: 63px !important; }

.ptb-63 {
  padding-top: 63px !important;
  padding-bottom: 63px !important; }

.pbt-63 {
  padding-top: 63px !important;
  padding-bottom: 63px !important; }

.ma-63 {
  margin: 63px !important; }

.mr-63 {
  margin-right: 63px !important; }

.mt-63 {
  margin-top: 63px !important; }

.mb-63 {
  margin-bottom: 63px !important; }

.ml-63 {
  margin-left: 63px !important; }

.mlr-63 {
  margin-left: 63px !important;
  margin-right: 63px !important; }

.mrl-63 {
  margin-left: 63px !important;
  margin-right: 63px !important; }

.mtb-63 {
  margin-top: 63px !important;
  margin-bottom: 63px !important; }

.mbt-63 {
  margin-top: 63px !important;
  margin-bottom: 63px !important; }

.border-radius-64 {
  border-radius: 64px !important; }

.fs-64 {
  font-size: 64px !important; }
  .fs-64.light-text {
    font-family: "montserrat-light"; }
  .fs-64.regular-text {
    font-family: "montserrat-regular"; }
  .fs-64.medium-text {
    font-family: "montserrat-medium"; }
  .fs-64.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-64.bold-text {
    font-family: "montserrat-bold"; }

.pa-64 {
  padding: 64px !important; }

.pr-64 {
  padding-right: 64px !important; }

.pl-64 {
  padding-left: 64px !important; }

.pt-64 {
  padding-top: 64px !important; }

.pb-64 {
  padding-bottom: 64px !important; }

.plr-64 {
  padding-left: 64px !important;
  padding-right: 64px !important; }

.prl-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important; }

.ptb-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important; }

.pbt-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important; }

.ma-64 {
  margin: 64px !important; }

.mr-64 {
  margin-right: 64px !important; }

.mt-64 {
  margin-top: 64px !important; }

.mb-64 {
  margin-bottom: 64px !important; }

.ml-64 {
  margin-left: 64px !important; }

.mlr-64 {
  margin-left: 64px !important;
  margin-right: 64px !important; }

.mrl-64 {
  margin-left: 64px !important;
  margin-right: 64px !important; }

.mtb-64 {
  margin-top: 64px !important;
  margin-bottom: 64px !important; }

.mbt-64 {
  margin-top: 64px !important;
  margin-bottom: 64px !important; }

.border-radius-65 {
  border-radius: 65px !important; }

.fs-65 {
  font-size: 65px !important; }
  .fs-65.light-text {
    font-family: "montserrat-light"; }
  .fs-65.regular-text {
    font-family: "montserrat-regular"; }
  .fs-65.medium-text {
    font-family: "montserrat-medium"; }
  .fs-65.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-65.bold-text {
    font-family: "montserrat-bold"; }

.pa-65 {
  padding: 65px !important; }

.pr-65 {
  padding-right: 65px !important; }

.pl-65 {
  padding-left: 65px !important; }

.pt-65 {
  padding-top: 65px !important; }

.pb-65 {
  padding-bottom: 65px !important; }

.plr-65 {
  padding-left: 65px !important;
  padding-right: 65px !important; }

.prl-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important; }

.ptb-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important; }

.pbt-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important; }

.ma-65 {
  margin: 65px !important; }

.mr-65 {
  margin-right: 65px !important; }

.mt-65 {
  margin-top: 65px !important; }

.mb-65 {
  margin-bottom: 65px !important; }

.ml-65 {
  margin-left: 65px !important; }

.mlr-65 {
  margin-left: 65px !important;
  margin-right: 65px !important; }

.mrl-65 {
  margin-left: 65px !important;
  margin-right: 65px !important; }

.mtb-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important; }

.mbt-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important; }

.border-radius-66 {
  border-radius: 66px !important; }

.fs-66 {
  font-size: 66px !important; }
  .fs-66.light-text {
    font-family: "montserrat-light"; }
  .fs-66.regular-text {
    font-family: "montserrat-regular"; }
  .fs-66.medium-text {
    font-family: "montserrat-medium"; }
  .fs-66.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-66.bold-text {
    font-family: "montserrat-bold"; }

.pa-66 {
  padding: 66px !important; }

.pr-66 {
  padding-right: 66px !important; }

.pl-66 {
  padding-left: 66px !important; }

.pt-66 {
  padding-top: 66px !important; }

.pb-66 {
  padding-bottom: 66px !important; }

.plr-66 {
  padding-left: 66px !important;
  padding-right: 66px !important; }

.prl-66 {
  padding-top: 66px !important;
  padding-bottom: 66px !important; }

.ptb-66 {
  padding-top: 66px !important;
  padding-bottom: 66px !important; }

.pbt-66 {
  padding-top: 66px !important;
  padding-bottom: 66px !important; }

.ma-66 {
  margin: 66px !important; }

.mr-66 {
  margin-right: 66px !important; }

.mt-66 {
  margin-top: 66px !important; }

.mb-66 {
  margin-bottom: 66px !important; }

.ml-66 {
  margin-left: 66px !important; }

.mlr-66 {
  margin-left: 66px !important;
  margin-right: 66px !important; }

.mrl-66 {
  margin-left: 66px !important;
  margin-right: 66px !important; }

.mtb-66 {
  margin-top: 66px !important;
  margin-bottom: 66px !important; }

.mbt-66 {
  margin-top: 66px !important;
  margin-bottom: 66px !important; }

.border-radius-67 {
  border-radius: 67px !important; }

.fs-67 {
  font-size: 67px !important; }
  .fs-67.light-text {
    font-family: "montserrat-light"; }
  .fs-67.regular-text {
    font-family: "montserrat-regular"; }
  .fs-67.medium-text {
    font-family: "montserrat-medium"; }
  .fs-67.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-67.bold-text {
    font-family: "montserrat-bold"; }

.pa-67 {
  padding: 67px !important; }

.pr-67 {
  padding-right: 67px !important; }

.pl-67 {
  padding-left: 67px !important; }

.pt-67 {
  padding-top: 67px !important; }

.pb-67 {
  padding-bottom: 67px !important; }

.plr-67 {
  padding-left: 67px !important;
  padding-right: 67px !important; }

.prl-67 {
  padding-top: 67px !important;
  padding-bottom: 67px !important; }

.ptb-67 {
  padding-top: 67px !important;
  padding-bottom: 67px !important; }

.pbt-67 {
  padding-top: 67px !important;
  padding-bottom: 67px !important; }

.ma-67 {
  margin: 67px !important; }

.mr-67 {
  margin-right: 67px !important; }

.mt-67 {
  margin-top: 67px !important; }

.mb-67 {
  margin-bottom: 67px !important; }

.ml-67 {
  margin-left: 67px !important; }

.mlr-67 {
  margin-left: 67px !important;
  margin-right: 67px !important; }

.mrl-67 {
  margin-left: 67px !important;
  margin-right: 67px !important; }

.mtb-67 {
  margin-top: 67px !important;
  margin-bottom: 67px !important; }

.mbt-67 {
  margin-top: 67px !important;
  margin-bottom: 67px !important; }

.border-radius-68 {
  border-radius: 68px !important; }

.fs-68 {
  font-size: 68px !important; }
  .fs-68.light-text {
    font-family: "montserrat-light"; }
  .fs-68.regular-text {
    font-family: "montserrat-regular"; }
  .fs-68.medium-text {
    font-family: "montserrat-medium"; }
  .fs-68.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-68.bold-text {
    font-family: "montserrat-bold"; }

.pa-68 {
  padding: 68px !important; }

.pr-68 {
  padding-right: 68px !important; }

.pl-68 {
  padding-left: 68px !important; }

.pt-68 {
  padding-top: 68px !important; }

.pb-68 {
  padding-bottom: 68px !important; }

.plr-68 {
  padding-left: 68px !important;
  padding-right: 68px !important; }

.prl-68 {
  padding-top: 68px !important;
  padding-bottom: 68px !important; }

.ptb-68 {
  padding-top: 68px !important;
  padding-bottom: 68px !important; }

.pbt-68 {
  padding-top: 68px !important;
  padding-bottom: 68px !important; }

.ma-68 {
  margin: 68px !important; }

.mr-68 {
  margin-right: 68px !important; }

.mt-68 {
  margin-top: 68px !important; }

.mb-68 {
  margin-bottom: 68px !important; }

.ml-68 {
  margin-left: 68px !important; }

.mlr-68 {
  margin-left: 68px !important;
  margin-right: 68px !important; }

.mrl-68 {
  margin-left: 68px !important;
  margin-right: 68px !important; }

.mtb-68 {
  margin-top: 68px !important;
  margin-bottom: 68px !important; }

.mbt-68 {
  margin-top: 68px !important;
  margin-bottom: 68px !important; }

.border-radius-69 {
  border-radius: 69px !important; }

.fs-69 {
  font-size: 69px !important; }
  .fs-69.light-text {
    font-family: "montserrat-light"; }
  .fs-69.regular-text {
    font-family: "montserrat-regular"; }
  .fs-69.medium-text {
    font-family: "montserrat-medium"; }
  .fs-69.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-69.bold-text {
    font-family: "montserrat-bold"; }

.pa-69 {
  padding: 69px !important; }

.pr-69 {
  padding-right: 69px !important; }

.pl-69 {
  padding-left: 69px !important; }

.pt-69 {
  padding-top: 69px !important; }

.pb-69 {
  padding-bottom: 69px !important; }

.plr-69 {
  padding-left: 69px !important;
  padding-right: 69px !important; }

.prl-69 {
  padding-top: 69px !important;
  padding-bottom: 69px !important; }

.ptb-69 {
  padding-top: 69px !important;
  padding-bottom: 69px !important; }

.pbt-69 {
  padding-top: 69px !important;
  padding-bottom: 69px !important; }

.ma-69 {
  margin: 69px !important; }

.mr-69 {
  margin-right: 69px !important; }

.mt-69 {
  margin-top: 69px !important; }

.mb-69 {
  margin-bottom: 69px !important; }

.ml-69 {
  margin-left: 69px !important; }

.mlr-69 {
  margin-left: 69px !important;
  margin-right: 69px !important; }

.mrl-69 {
  margin-left: 69px !important;
  margin-right: 69px !important; }

.mtb-69 {
  margin-top: 69px !important;
  margin-bottom: 69px !important; }

.mbt-69 {
  margin-top: 69px !important;
  margin-bottom: 69px !important; }

.border-radius-70 {
  border-radius: 70px !important; }

.fs-70 {
  font-size: 70px !important; }
  .fs-70.light-text {
    font-family: "montserrat-light"; }
  .fs-70.regular-text {
    font-family: "montserrat-regular"; }
  .fs-70.medium-text {
    font-family: "montserrat-medium"; }
  .fs-70.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-70.bold-text {
    font-family: "montserrat-bold"; }

.pa-70 {
  padding: 70px !important; }

.pr-70 {
  padding-right: 70px !important; }

.pl-70 {
  padding-left: 70px !important; }

.pt-70 {
  padding-top: 70px !important; }

.pb-70 {
  padding-bottom: 70px !important; }

.plr-70 {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.prl-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.ptb-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.pbt-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.ma-70 {
  margin: 70px !important; }

.mr-70 {
  margin-right: 70px !important; }

.mt-70 {
  margin-top: 70px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.ml-70 {
  margin-left: 70px !important; }

.mlr-70 {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.mrl-70 {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.mtb-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.mbt-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.border-radius-71 {
  border-radius: 71px !important; }

.fs-71 {
  font-size: 71px !important; }
  .fs-71.light-text {
    font-family: "montserrat-light"; }
  .fs-71.regular-text {
    font-family: "montserrat-regular"; }
  .fs-71.medium-text {
    font-family: "montserrat-medium"; }
  .fs-71.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-71.bold-text {
    font-family: "montserrat-bold"; }

.pa-71 {
  padding: 71px !important; }

.pr-71 {
  padding-right: 71px !important; }

.pl-71 {
  padding-left: 71px !important; }

.pt-71 {
  padding-top: 71px !important; }

.pb-71 {
  padding-bottom: 71px !important; }

.plr-71 {
  padding-left: 71px !important;
  padding-right: 71px !important; }

.prl-71 {
  padding-top: 71px !important;
  padding-bottom: 71px !important; }

.ptb-71 {
  padding-top: 71px !important;
  padding-bottom: 71px !important; }

.pbt-71 {
  padding-top: 71px !important;
  padding-bottom: 71px !important; }

.ma-71 {
  margin: 71px !important; }

.mr-71 {
  margin-right: 71px !important; }

.mt-71 {
  margin-top: 71px !important; }

.mb-71 {
  margin-bottom: 71px !important; }

.ml-71 {
  margin-left: 71px !important; }

.mlr-71 {
  margin-left: 71px !important;
  margin-right: 71px !important; }

.mrl-71 {
  margin-left: 71px !important;
  margin-right: 71px !important; }

.mtb-71 {
  margin-top: 71px !important;
  margin-bottom: 71px !important; }

.mbt-71 {
  margin-top: 71px !important;
  margin-bottom: 71px !important; }

.border-radius-72 {
  border-radius: 72px !important; }

.fs-72 {
  font-size: 72px !important; }
  .fs-72.light-text {
    font-family: "montserrat-light"; }
  .fs-72.regular-text {
    font-family: "montserrat-regular"; }
  .fs-72.medium-text {
    font-family: "montserrat-medium"; }
  .fs-72.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-72.bold-text {
    font-family: "montserrat-bold"; }

.pa-72 {
  padding: 72px !important; }

.pr-72 {
  padding-right: 72px !important; }

.pl-72 {
  padding-left: 72px !important; }

.pt-72 {
  padding-top: 72px !important; }

.pb-72 {
  padding-bottom: 72px !important; }

.plr-72 {
  padding-left: 72px !important;
  padding-right: 72px !important; }

.prl-72 {
  padding-top: 72px !important;
  padding-bottom: 72px !important; }

.ptb-72 {
  padding-top: 72px !important;
  padding-bottom: 72px !important; }

.pbt-72 {
  padding-top: 72px !important;
  padding-bottom: 72px !important; }

.ma-72 {
  margin: 72px !important; }

.mr-72 {
  margin-right: 72px !important; }

.mt-72 {
  margin-top: 72px !important; }

.mb-72 {
  margin-bottom: 72px !important; }

.ml-72 {
  margin-left: 72px !important; }

.mlr-72 {
  margin-left: 72px !important;
  margin-right: 72px !important; }

.mrl-72 {
  margin-left: 72px !important;
  margin-right: 72px !important; }

.mtb-72 {
  margin-top: 72px !important;
  margin-bottom: 72px !important; }

.mbt-72 {
  margin-top: 72px !important;
  margin-bottom: 72px !important; }

.border-radius-73 {
  border-radius: 73px !important; }

.fs-73 {
  font-size: 73px !important; }
  .fs-73.light-text {
    font-family: "montserrat-light"; }
  .fs-73.regular-text {
    font-family: "montserrat-regular"; }
  .fs-73.medium-text {
    font-family: "montserrat-medium"; }
  .fs-73.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-73.bold-text {
    font-family: "montserrat-bold"; }

.pa-73 {
  padding: 73px !important; }

.pr-73 {
  padding-right: 73px !important; }

.pl-73 {
  padding-left: 73px !important; }

.pt-73 {
  padding-top: 73px !important; }

.pb-73 {
  padding-bottom: 73px !important; }

.plr-73 {
  padding-left: 73px !important;
  padding-right: 73px !important; }

.prl-73 {
  padding-top: 73px !important;
  padding-bottom: 73px !important; }

.ptb-73 {
  padding-top: 73px !important;
  padding-bottom: 73px !important; }

.pbt-73 {
  padding-top: 73px !important;
  padding-bottom: 73px !important; }

.ma-73 {
  margin: 73px !important; }

.mr-73 {
  margin-right: 73px !important; }

.mt-73 {
  margin-top: 73px !important; }

.mb-73 {
  margin-bottom: 73px !important; }

.ml-73 {
  margin-left: 73px !important; }

.mlr-73 {
  margin-left: 73px !important;
  margin-right: 73px !important; }

.mrl-73 {
  margin-left: 73px !important;
  margin-right: 73px !important; }

.mtb-73 {
  margin-top: 73px !important;
  margin-bottom: 73px !important; }

.mbt-73 {
  margin-top: 73px !important;
  margin-bottom: 73px !important; }

.border-radius-74 {
  border-radius: 74px !important; }

.fs-74 {
  font-size: 74px !important; }
  .fs-74.light-text {
    font-family: "montserrat-light"; }
  .fs-74.regular-text {
    font-family: "montserrat-regular"; }
  .fs-74.medium-text {
    font-family: "montserrat-medium"; }
  .fs-74.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-74.bold-text {
    font-family: "montserrat-bold"; }

.pa-74 {
  padding: 74px !important; }

.pr-74 {
  padding-right: 74px !important; }

.pl-74 {
  padding-left: 74px !important; }

.pt-74 {
  padding-top: 74px !important; }

.pb-74 {
  padding-bottom: 74px !important; }

.plr-74 {
  padding-left: 74px !important;
  padding-right: 74px !important; }

.prl-74 {
  padding-top: 74px !important;
  padding-bottom: 74px !important; }

.ptb-74 {
  padding-top: 74px !important;
  padding-bottom: 74px !important; }

.pbt-74 {
  padding-top: 74px !important;
  padding-bottom: 74px !important; }

.ma-74 {
  margin: 74px !important; }

.mr-74 {
  margin-right: 74px !important; }

.mt-74 {
  margin-top: 74px !important; }

.mb-74 {
  margin-bottom: 74px !important; }

.ml-74 {
  margin-left: 74px !important; }

.mlr-74 {
  margin-left: 74px !important;
  margin-right: 74px !important; }

.mrl-74 {
  margin-left: 74px !important;
  margin-right: 74px !important; }

.mtb-74 {
  margin-top: 74px !important;
  margin-bottom: 74px !important; }

.mbt-74 {
  margin-top: 74px !important;
  margin-bottom: 74px !important; }

.border-radius-75 {
  border-radius: 75px !important; }

.fs-75 {
  font-size: 75px !important; }
  .fs-75.light-text {
    font-family: "montserrat-light"; }
  .fs-75.regular-text {
    font-family: "montserrat-regular"; }
  .fs-75.medium-text {
    font-family: "montserrat-medium"; }
  .fs-75.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-75.bold-text {
    font-family: "montserrat-bold"; }

.pa-75 {
  padding: 75px !important; }

.pr-75 {
  padding-right: 75px !important; }

.pl-75 {
  padding-left: 75px !important; }

.pt-75 {
  padding-top: 75px !important; }

.pb-75 {
  padding-bottom: 75px !important; }

.plr-75 {
  padding-left: 75px !important;
  padding-right: 75px !important; }

.prl-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important; }

.ptb-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important; }

.pbt-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important; }

.ma-75 {
  margin: 75px !important; }

.mr-75 {
  margin-right: 75px !important; }

.mt-75 {
  margin-top: 75px !important; }

.mb-75 {
  margin-bottom: 75px !important; }

.ml-75 {
  margin-left: 75px !important; }

.mlr-75 {
  margin-left: 75px !important;
  margin-right: 75px !important; }

.mrl-75 {
  margin-left: 75px !important;
  margin-right: 75px !important; }

.mtb-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important; }

.mbt-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important; }

.border-radius-76 {
  border-radius: 76px !important; }

.fs-76 {
  font-size: 76px !important; }
  .fs-76.light-text {
    font-family: "montserrat-light"; }
  .fs-76.regular-text {
    font-family: "montserrat-regular"; }
  .fs-76.medium-text {
    font-family: "montserrat-medium"; }
  .fs-76.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-76.bold-text {
    font-family: "montserrat-bold"; }

.pa-76 {
  padding: 76px !important; }

.pr-76 {
  padding-right: 76px !important; }

.pl-76 {
  padding-left: 76px !important; }

.pt-76 {
  padding-top: 76px !important; }

.pb-76 {
  padding-bottom: 76px !important; }

.plr-76 {
  padding-left: 76px !important;
  padding-right: 76px !important; }

.prl-76 {
  padding-top: 76px !important;
  padding-bottom: 76px !important; }

.ptb-76 {
  padding-top: 76px !important;
  padding-bottom: 76px !important; }

.pbt-76 {
  padding-top: 76px !important;
  padding-bottom: 76px !important; }

.ma-76 {
  margin: 76px !important; }

.mr-76 {
  margin-right: 76px !important; }

.mt-76 {
  margin-top: 76px !important; }

.mb-76 {
  margin-bottom: 76px !important; }

.ml-76 {
  margin-left: 76px !important; }

.mlr-76 {
  margin-left: 76px !important;
  margin-right: 76px !important; }

.mrl-76 {
  margin-left: 76px !important;
  margin-right: 76px !important; }

.mtb-76 {
  margin-top: 76px !important;
  margin-bottom: 76px !important; }

.mbt-76 {
  margin-top: 76px !important;
  margin-bottom: 76px !important; }

.border-radius-77 {
  border-radius: 77px !important; }

.fs-77 {
  font-size: 77px !important; }
  .fs-77.light-text {
    font-family: "montserrat-light"; }
  .fs-77.regular-text {
    font-family: "montserrat-regular"; }
  .fs-77.medium-text {
    font-family: "montserrat-medium"; }
  .fs-77.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-77.bold-text {
    font-family: "montserrat-bold"; }

.pa-77 {
  padding: 77px !important; }

.pr-77 {
  padding-right: 77px !important; }

.pl-77 {
  padding-left: 77px !important; }

.pt-77 {
  padding-top: 77px !important; }

.pb-77 {
  padding-bottom: 77px !important; }

.plr-77 {
  padding-left: 77px !important;
  padding-right: 77px !important; }

.prl-77 {
  padding-top: 77px !important;
  padding-bottom: 77px !important; }

.ptb-77 {
  padding-top: 77px !important;
  padding-bottom: 77px !important; }

.pbt-77 {
  padding-top: 77px !important;
  padding-bottom: 77px !important; }

.ma-77 {
  margin: 77px !important; }

.mr-77 {
  margin-right: 77px !important; }

.mt-77 {
  margin-top: 77px !important; }

.mb-77 {
  margin-bottom: 77px !important; }

.ml-77 {
  margin-left: 77px !important; }

.mlr-77 {
  margin-left: 77px !important;
  margin-right: 77px !important; }

.mrl-77 {
  margin-left: 77px !important;
  margin-right: 77px !important; }

.mtb-77 {
  margin-top: 77px !important;
  margin-bottom: 77px !important; }

.mbt-77 {
  margin-top: 77px !important;
  margin-bottom: 77px !important; }

.border-radius-78 {
  border-radius: 78px !important; }

.fs-78 {
  font-size: 78px !important; }
  .fs-78.light-text {
    font-family: "montserrat-light"; }
  .fs-78.regular-text {
    font-family: "montserrat-regular"; }
  .fs-78.medium-text {
    font-family: "montserrat-medium"; }
  .fs-78.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-78.bold-text {
    font-family: "montserrat-bold"; }

.pa-78 {
  padding: 78px !important; }

.pr-78 {
  padding-right: 78px !important; }

.pl-78 {
  padding-left: 78px !important; }

.pt-78 {
  padding-top: 78px !important; }

.pb-78 {
  padding-bottom: 78px !important; }

.plr-78 {
  padding-left: 78px !important;
  padding-right: 78px !important; }

.prl-78 {
  padding-top: 78px !important;
  padding-bottom: 78px !important; }

.ptb-78 {
  padding-top: 78px !important;
  padding-bottom: 78px !important; }

.pbt-78 {
  padding-top: 78px !important;
  padding-bottom: 78px !important; }

.ma-78 {
  margin: 78px !important; }

.mr-78 {
  margin-right: 78px !important; }

.mt-78 {
  margin-top: 78px !important; }

.mb-78 {
  margin-bottom: 78px !important; }

.ml-78 {
  margin-left: 78px !important; }

.mlr-78 {
  margin-left: 78px !important;
  margin-right: 78px !important; }

.mrl-78 {
  margin-left: 78px !important;
  margin-right: 78px !important; }

.mtb-78 {
  margin-top: 78px !important;
  margin-bottom: 78px !important; }

.mbt-78 {
  margin-top: 78px !important;
  margin-bottom: 78px !important; }

.border-radius-79 {
  border-radius: 79px !important; }

.fs-79 {
  font-size: 79px !important; }
  .fs-79.light-text {
    font-family: "montserrat-light"; }
  .fs-79.regular-text {
    font-family: "montserrat-regular"; }
  .fs-79.medium-text {
    font-family: "montserrat-medium"; }
  .fs-79.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-79.bold-text {
    font-family: "montserrat-bold"; }

.pa-79 {
  padding: 79px !important; }

.pr-79 {
  padding-right: 79px !important; }

.pl-79 {
  padding-left: 79px !important; }

.pt-79 {
  padding-top: 79px !important; }

.pb-79 {
  padding-bottom: 79px !important; }

.plr-79 {
  padding-left: 79px !important;
  padding-right: 79px !important; }

.prl-79 {
  padding-top: 79px !important;
  padding-bottom: 79px !important; }

.ptb-79 {
  padding-top: 79px !important;
  padding-bottom: 79px !important; }

.pbt-79 {
  padding-top: 79px !important;
  padding-bottom: 79px !important; }

.ma-79 {
  margin: 79px !important; }

.mr-79 {
  margin-right: 79px !important; }

.mt-79 {
  margin-top: 79px !important; }

.mb-79 {
  margin-bottom: 79px !important; }

.ml-79 {
  margin-left: 79px !important; }

.mlr-79 {
  margin-left: 79px !important;
  margin-right: 79px !important; }

.mrl-79 {
  margin-left: 79px !important;
  margin-right: 79px !important; }

.mtb-79 {
  margin-top: 79px !important;
  margin-bottom: 79px !important; }

.mbt-79 {
  margin-top: 79px !important;
  margin-bottom: 79px !important; }

.border-radius-80 {
  border-radius: 80px !important; }

.fs-80 {
  font-size: 80px !important; }
  .fs-80.light-text {
    font-family: "montserrat-light"; }
  .fs-80.regular-text {
    font-family: "montserrat-regular"; }
  .fs-80.medium-text {
    font-family: "montserrat-medium"; }
  .fs-80.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-80.bold-text {
    font-family: "montserrat-bold"; }

.pa-80 {
  padding: 80px !important; }

.pr-80 {
  padding-right: 80px !important; }

.pl-80 {
  padding-left: 80px !important; }

.pt-80 {
  padding-top: 80px !important; }

.pb-80 {
  padding-bottom: 80px !important; }

.plr-80 {
  padding-left: 80px !important;
  padding-right: 80px !important; }

.prl-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.ptb-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.pbt-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.ma-80 {
  margin: 80px !important; }

.mr-80 {
  margin-right: 80px !important; }

.mt-80 {
  margin-top: 80px !important; }

.mb-80 {
  margin-bottom: 80px !important; }

.ml-80 {
  margin-left: 80px !important; }

.mlr-80 {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.mrl-80 {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.mtb-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.mbt-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.border-radius-81 {
  border-radius: 81px !important; }

.fs-81 {
  font-size: 81px !important; }
  .fs-81.light-text {
    font-family: "montserrat-light"; }
  .fs-81.regular-text {
    font-family: "montserrat-regular"; }
  .fs-81.medium-text {
    font-family: "montserrat-medium"; }
  .fs-81.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-81.bold-text {
    font-family: "montserrat-bold"; }

.pa-81 {
  padding: 81px !important; }

.pr-81 {
  padding-right: 81px !important; }

.pl-81 {
  padding-left: 81px !important; }

.pt-81 {
  padding-top: 81px !important; }

.pb-81 {
  padding-bottom: 81px !important; }

.plr-81 {
  padding-left: 81px !important;
  padding-right: 81px !important; }

.prl-81 {
  padding-top: 81px !important;
  padding-bottom: 81px !important; }

.ptb-81 {
  padding-top: 81px !important;
  padding-bottom: 81px !important; }

.pbt-81 {
  padding-top: 81px !important;
  padding-bottom: 81px !important; }

.ma-81 {
  margin: 81px !important; }

.mr-81 {
  margin-right: 81px !important; }

.mt-81 {
  margin-top: 81px !important; }

.mb-81 {
  margin-bottom: 81px !important; }

.ml-81 {
  margin-left: 81px !important; }

.mlr-81 {
  margin-left: 81px !important;
  margin-right: 81px !important; }

.mrl-81 {
  margin-left: 81px !important;
  margin-right: 81px !important; }

.mtb-81 {
  margin-top: 81px !important;
  margin-bottom: 81px !important; }

.mbt-81 {
  margin-top: 81px !important;
  margin-bottom: 81px !important; }

.border-radius-82 {
  border-radius: 82px !important; }

.fs-82 {
  font-size: 82px !important; }
  .fs-82.light-text {
    font-family: "montserrat-light"; }
  .fs-82.regular-text {
    font-family: "montserrat-regular"; }
  .fs-82.medium-text {
    font-family: "montserrat-medium"; }
  .fs-82.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-82.bold-text {
    font-family: "montserrat-bold"; }

.pa-82 {
  padding: 82px !important; }

.pr-82 {
  padding-right: 82px !important; }

.pl-82 {
  padding-left: 82px !important; }

.pt-82 {
  padding-top: 82px !important; }

.pb-82 {
  padding-bottom: 82px !important; }

.plr-82 {
  padding-left: 82px !important;
  padding-right: 82px !important; }

.prl-82 {
  padding-top: 82px !important;
  padding-bottom: 82px !important; }

.ptb-82 {
  padding-top: 82px !important;
  padding-bottom: 82px !important; }

.pbt-82 {
  padding-top: 82px !important;
  padding-bottom: 82px !important; }

.ma-82 {
  margin: 82px !important; }

.mr-82 {
  margin-right: 82px !important; }

.mt-82 {
  margin-top: 82px !important; }

.mb-82 {
  margin-bottom: 82px !important; }

.ml-82 {
  margin-left: 82px !important; }

.mlr-82 {
  margin-left: 82px !important;
  margin-right: 82px !important; }

.mrl-82 {
  margin-left: 82px !important;
  margin-right: 82px !important; }

.mtb-82 {
  margin-top: 82px !important;
  margin-bottom: 82px !important; }

.mbt-82 {
  margin-top: 82px !important;
  margin-bottom: 82px !important; }

.border-radius-83 {
  border-radius: 83px !important; }

.fs-83 {
  font-size: 83px !important; }
  .fs-83.light-text {
    font-family: "montserrat-light"; }
  .fs-83.regular-text {
    font-family: "montserrat-regular"; }
  .fs-83.medium-text {
    font-family: "montserrat-medium"; }
  .fs-83.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-83.bold-text {
    font-family: "montserrat-bold"; }

.pa-83 {
  padding: 83px !important; }

.pr-83 {
  padding-right: 83px !important; }

.pl-83 {
  padding-left: 83px !important; }

.pt-83 {
  padding-top: 83px !important; }

.pb-83 {
  padding-bottom: 83px !important; }

.plr-83 {
  padding-left: 83px !important;
  padding-right: 83px !important; }

.prl-83 {
  padding-top: 83px !important;
  padding-bottom: 83px !important; }

.ptb-83 {
  padding-top: 83px !important;
  padding-bottom: 83px !important; }

.pbt-83 {
  padding-top: 83px !important;
  padding-bottom: 83px !important; }

.ma-83 {
  margin: 83px !important; }

.mr-83 {
  margin-right: 83px !important; }

.mt-83 {
  margin-top: 83px !important; }

.mb-83 {
  margin-bottom: 83px !important; }

.ml-83 {
  margin-left: 83px !important; }

.mlr-83 {
  margin-left: 83px !important;
  margin-right: 83px !important; }

.mrl-83 {
  margin-left: 83px !important;
  margin-right: 83px !important; }

.mtb-83 {
  margin-top: 83px !important;
  margin-bottom: 83px !important; }

.mbt-83 {
  margin-top: 83px !important;
  margin-bottom: 83px !important; }

.border-radius-84 {
  border-radius: 84px !important; }

.fs-84 {
  font-size: 84px !important; }
  .fs-84.light-text {
    font-family: "montserrat-light"; }
  .fs-84.regular-text {
    font-family: "montserrat-regular"; }
  .fs-84.medium-text {
    font-family: "montserrat-medium"; }
  .fs-84.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-84.bold-text {
    font-family: "montserrat-bold"; }

.pa-84 {
  padding: 84px !important; }

.pr-84 {
  padding-right: 84px !important; }

.pl-84 {
  padding-left: 84px !important; }

.pt-84 {
  padding-top: 84px !important; }

.pb-84 {
  padding-bottom: 84px !important; }

.plr-84 {
  padding-left: 84px !important;
  padding-right: 84px !important; }

.prl-84 {
  padding-top: 84px !important;
  padding-bottom: 84px !important; }

.ptb-84 {
  padding-top: 84px !important;
  padding-bottom: 84px !important; }

.pbt-84 {
  padding-top: 84px !important;
  padding-bottom: 84px !important; }

.ma-84 {
  margin: 84px !important; }

.mr-84 {
  margin-right: 84px !important; }

.mt-84 {
  margin-top: 84px !important; }

.mb-84 {
  margin-bottom: 84px !important; }

.ml-84 {
  margin-left: 84px !important; }

.mlr-84 {
  margin-left: 84px !important;
  margin-right: 84px !important; }

.mrl-84 {
  margin-left: 84px !important;
  margin-right: 84px !important; }

.mtb-84 {
  margin-top: 84px !important;
  margin-bottom: 84px !important; }

.mbt-84 {
  margin-top: 84px !important;
  margin-bottom: 84px !important; }

.border-radius-85 {
  border-radius: 85px !important; }

.fs-85 {
  font-size: 85px !important; }
  .fs-85.light-text {
    font-family: "montserrat-light"; }
  .fs-85.regular-text {
    font-family: "montserrat-regular"; }
  .fs-85.medium-text {
    font-family: "montserrat-medium"; }
  .fs-85.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-85.bold-text {
    font-family: "montserrat-bold"; }

.pa-85 {
  padding: 85px !important; }

.pr-85 {
  padding-right: 85px !important; }

.pl-85 {
  padding-left: 85px !important; }

.pt-85 {
  padding-top: 85px !important; }

.pb-85 {
  padding-bottom: 85px !important; }

.plr-85 {
  padding-left: 85px !important;
  padding-right: 85px !important; }

.prl-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important; }

.ptb-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important; }

.pbt-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important; }

.ma-85 {
  margin: 85px !important; }

.mr-85 {
  margin-right: 85px !important; }

.mt-85 {
  margin-top: 85px !important; }

.mb-85 {
  margin-bottom: 85px !important; }

.ml-85 {
  margin-left: 85px !important; }

.mlr-85 {
  margin-left: 85px !important;
  margin-right: 85px !important; }

.mrl-85 {
  margin-left: 85px !important;
  margin-right: 85px !important; }

.mtb-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important; }

.mbt-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important; }

.border-radius-86 {
  border-radius: 86px !important; }

.fs-86 {
  font-size: 86px !important; }
  .fs-86.light-text {
    font-family: "montserrat-light"; }
  .fs-86.regular-text {
    font-family: "montserrat-regular"; }
  .fs-86.medium-text {
    font-family: "montserrat-medium"; }
  .fs-86.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-86.bold-text {
    font-family: "montserrat-bold"; }

.pa-86 {
  padding: 86px !important; }

.pr-86 {
  padding-right: 86px !important; }

.pl-86 {
  padding-left: 86px !important; }

.pt-86 {
  padding-top: 86px !important; }

.pb-86 {
  padding-bottom: 86px !important; }

.plr-86 {
  padding-left: 86px !important;
  padding-right: 86px !important; }

.prl-86 {
  padding-top: 86px !important;
  padding-bottom: 86px !important; }

.ptb-86 {
  padding-top: 86px !important;
  padding-bottom: 86px !important; }

.pbt-86 {
  padding-top: 86px !important;
  padding-bottom: 86px !important; }

.ma-86 {
  margin: 86px !important; }

.mr-86 {
  margin-right: 86px !important; }

.mt-86 {
  margin-top: 86px !important; }

.mb-86 {
  margin-bottom: 86px !important; }

.ml-86 {
  margin-left: 86px !important; }

.mlr-86 {
  margin-left: 86px !important;
  margin-right: 86px !important; }

.mrl-86 {
  margin-left: 86px !important;
  margin-right: 86px !important; }

.mtb-86 {
  margin-top: 86px !important;
  margin-bottom: 86px !important; }

.mbt-86 {
  margin-top: 86px !important;
  margin-bottom: 86px !important; }

.border-radius-87 {
  border-radius: 87px !important; }

.fs-87 {
  font-size: 87px !important; }
  .fs-87.light-text {
    font-family: "montserrat-light"; }
  .fs-87.regular-text {
    font-family: "montserrat-regular"; }
  .fs-87.medium-text {
    font-family: "montserrat-medium"; }
  .fs-87.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-87.bold-text {
    font-family: "montserrat-bold"; }

.pa-87 {
  padding: 87px !important; }

.pr-87 {
  padding-right: 87px !important; }

.pl-87 {
  padding-left: 87px !important; }

.pt-87 {
  padding-top: 87px !important; }

.pb-87 {
  padding-bottom: 87px !important; }

.plr-87 {
  padding-left: 87px !important;
  padding-right: 87px !important; }

.prl-87 {
  padding-top: 87px !important;
  padding-bottom: 87px !important; }

.ptb-87 {
  padding-top: 87px !important;
  padding-bottom: 87px !important; }

.pbt-87 {
  padding-top: 87px !important;
  padding-bottom: 87px !important; }

.ma-87 {
  margin: 87px !important; }

.mr-87 {
  margin-right: 87px !important; }

.mt-87 {
  margin-top: 87px !important; }

.mb-87 {
  margin-bottom: 87px !important; }

.ml-87 {
  margin-left: 87px !important; }

.mlr-87 {
  margin-left: 87px !important;
  margin-right: 87px !important; }

.mrl-87 {
  margin-left: 87px !important;
  margin-right: 87px !important; }

.mtb-87 {
  margin-top: 87px !important;
  margin-bottom: 87px !important; }

.mbt-87 {
  margin-top: 87px !important;
  margin-bottom: 87px !important; }

.border-radius-88 {
  border-radius: 88px !important; }

.fs-88 {
  font-size: 88px !important; }
  .fs-88.light-text {
    font-family: "montserrat-light"; }
  .fs-88.regular-text {
    font-family: "montserrat-regular"; }
  .fs-88.medium-text {
    font-family: "montserrat-medium"; }
  .fs-88.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-88.bold-text {
    font-family: "montserrat-bold"; }

.pa-88 {
  padding: 88px !important; }

.pr-88 {
  padding-right: 88px !important; }

.pl-88 {
  padding-left: 88px !important; }

.pt-88 {
  padding-top: 88px !important; }

.pb-88 {
  padding-bottom: 88px !important; }

.plr-88 {
  padding-left: 88px !important;
  padding-right: 88px !important; }

.prl-88 {
  padding-top: 88px !important;
  padding-bottom: 88px !important; }

.ptb-88 {
  padding-top: 88px !important;
  padding-bottom: 88px !important; }

.pbt-88 {
  padding-top: 88px !important;
  padding-bottom: 88px !important; }

.ma-88 {
  margin: 88px !important; }

.mr-88 {
  margin-right: 88px !important; }

.mt-88 {
  margin-top: 88px !important; }

.mb-88 {
  margin-bottom: 88px !important; }

.ml-88 {
  margin-left: 88px !important; }

.mlr-88 {
  margin-left: 88px !important;
  margin-right: 88px !important; }

.mrl-88 {
  margin-left: 88px !important;
  margin-right: 88px !important; }

.mtb-88 {
  margin-top: 88px !important;
  margin-bottom: 88px !important; }

.mbt-88 {
  margin-top: 88px !important;
  margin-bottom: 88px !important; }

.border-radius-89 {
  border-radius: 89px !important; }

.fs-89 {
  font-size: 89px !important; }
  .fs-89.light-text {
    font-family: "montserrat-light"; }
  .fs-89.regular-text {
    font-family: "montserrat-regular"; }
  .fs-89.medium-text {
    font-family: "montserrat-medium"; }
  .fs-89.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-89.bold-text {
    font-family: "montserrat-bold"; }

.pa-89 {
  padding: 89px !important; }

.pr-89 {
  padding-right: 89px !important; }

.pl-89 {
  padding-left: 89px !important; }

.pt-89 {
  padding-top: 89px !important; }

.pb-89 {
  padding-bottom: 89px !important; }

.plr-89 {
  padding-left: 89px !important;
  padding-right: 89px !important; }

.prl-89 {
  padding-top: 89px !important;
  padding-bottom: 89px !important; }

.ptb-89 {
  padding-top: 89px !important;
  padding-bottom: 89px !important; }

.pbt-89 {
  padding-top: 89px !important;
  padding-bottom: 89px !important; }

.ma-89 {
  margin: 89px !important; }

.mr-89 {
  margin-right: 89px !important; }

.mt-89 {
  margin-top: 89px !important; }

.mb-89 {
  margin-bottom: 89px !important; }

.ml-89 {
  margin-left: 89px !important; }

.mlr-89 {
  margin-left: 89px !important;
  margin-right: 89px !important; }

.mrl-89 {
  margin-left: 89px !important;
  margin-right: 89px !important; }

.mtb-89 {
  margin-top: 89px !important;
  margin-bottom: 89px !important; }

.mbt-89 {
  margin-top: 89px !important;
  margin-bottom: 89px !important; }

.border-radius-90 {
  border-radius: 90px !important; }

.fs-90 {
  font-size: 90px !important; }
  .fs-90.light-text {
    font-family: "montserrat-light"; }
  .fs-90.regular-text {
    font-family: "montserrat-regular"; }
  .fs-90.medium-text {
    font-family: "montserrat-medium"; }
  .fs-90.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-90.bold-text {
    font-family: "montserrat-bold"; }

.pa-90 {
  padding: 90px !important; }

.pr-90 {
  padding-right: 90px !important; }

.pl-90 {
  padding-left: 90px !important; }

.pt-90 {
  padding-top: 90px !important; }

.pb-90 {
  padding-bottom: 90px !important; }

.plr-90 {
  padding-left: 90px !important;
  padding-right: 90px !important; }

.prl-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important; }

.ptb-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important; }

.pbt-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important; }

.ma-90 {
  margin: 90px !important; }

.mr-90 {
  margin-right: 90px !important; }

.mt-90 {
  margin-top: 90px !important; }

.mb-90 {
  margin-bottom: 90px !important; }

.ml-90 {
  margin-left: 90px !important; }

.mlr-90 {
  margin-left: 90px !important;
  margin-right: 90px !important; }

.mrl-90 {
  margin-left: 90px !important;
  margin-right: 90px !important; }

.mtb-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important; }

.mbt-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important; }

.border-radius-91 {
  border-radius: 91px !important; }

.fs-91 {
  font-size: 91px !important; }
  .fs-91.light-text {
    font-family: "montserrat-light"; }
  .fs-91.regular-text {
    font-family: "montserrat-regular"; }
  .fs-91.medium-text {
    font-family: "montserrat-medium"; }
  .fs-91.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-91.bold-text {
    font-family: "montserrat-bold"; }

.pa-91 {
  padding: 91px !important; }

.pr-91 {
  padding-right: 91px !important; }

.pl-91 {
  padding-left: 91px !important; }

.pt-91 {
  padding-top: 91px !important; }

.pb-91 {
  padding-bottom: 91px !important; }

.plr-91 {
  padding-left: 91px !important;
  padding-right: 91px !important; }

.prl-91 {
  padding-top: 91px !important;
  padding-bottom: 91px !important; }

.ptb-91 {
  padding-top: 91px !important;
  padding-bottom: 91px !important; }

.pbt-91 {
  padding-top: 91px !important;
  padding-bottom: 91px !important; }

.ma-91 {
  margin: 91px !important; }

.mr-91 {
  margin-right: 91px !important; }

.mt-91 {
  margin-top: 91px !important; }

.mb-91 {
  margin-bottom: 91px !important; }

.ml-91 {
  margin-left: 91px !important; }

.mlr-91 {
  margin-left: 91px !important;
  margin-right: 91px !important; }

.mrl-91 {
  margin-left: 91px !important;
  margin-right: 91px !important; }

.mtb-91 {
  margin-top: 91px !important;
  margin-bottom: 91px !important; }

.mbt-91 {
  margin-top: 91px !important;
  margin-bottom: 91px !important; }

.border-radius-92 {
  border-radius: 92px !important; }

.fs-92 {
  font-size: 92px !important; }
  .fs-92.light-text {
    font-family: "montserrat-light"; }
  .fs-92.regular-text {
    font-family: "montserrat-regular"; }
  .fs-92.medium-text {
    font-family: "montserrat-medium"; }
  .fs-92.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-92.bold-text {
    font-family: "montserrat-bold"; }

.pa-92 {
  padding: 92px !important; }

.pr-92 {
  padding-right: 92px !important; }

.pl-92 {
  padding-left: 92px !important; }

.pt-92 {
  padding-top: 92px !important; }

.pb-92 {
  padding-bottom: 92px !important; }

.plr-92 {
  padding-left: 92px !important;
  padding-right: 92px !important; }

.prl-92 {
  padding-top: 92px !important;
  padding-bottom: 92px !important; }

.ptb-92 {
  padding-top: 92px !important;
  padding-bottom: 92px !important; }

.pbt-92 {
  padding-top: 92px !important;
  padding-bottom: 92px !important; }

.ma-92 {
  margin: 92px !important; }

.mr-92 {
  margin-right: 92px !important; }

.mt-92 {
  margin-top: 92px !important; }

.mb-92 {
  margin-bottom: 92px !important; }

.ml-92 {
  margin-left: 92px !important; }

.mlr-92 {
  margin-left: 92px !important;
  margin-right: 92px !important; }

.mrl-92 {
  margin-left: 92px !important;
  margin-right: 92px !important; }

.mtb-92 {
  margin-top: 92px !important;
  margin-bottom: 92px !important; }

.mbt-92 {
  margin-top: 92px !important;
  margin-bottom: 92px !important; }

.border-radius-93 {
  border-radius: 93px !important; }

.fs-93 {
  font-size: 93px !important; }
  .fs-93.light-text {
    font-family: "montserrat-light"; }
  .fs-93.regular-text {
    font-family: "montserrat-regular"; }
  .fs-93.medium-text {
    font-family: "montserrat-medium"; }
  .fs-93.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-93.bold-text {
    font-family: "montserrat-bold"; }

.pa-93 {
  padding: 93px !important; }

.pr-93 {
  padding-right: 93px !important; }

.pl-93 {
  padding-left: 93px !important; }

.pt-93 {
  padding-top: 93px !important; }

.pb-93 {
  padding-bottom: 93px !important; }

.plr-93 {
  padding-left: 93px !important;
  padding-right: 93px !important; }

.prl-93 {
  padding-top: 93px !important;
  padding-bottom: 93px !important; }

.ptb-93 {
  padding-top: 93px !important;
  padding-bottom: 93px !important; }

.pbt-93 {
  padding-top: 93px !important;
  padding-bottom: 93px !important; }

.ma-93 {
  margin: 93px !important; }

.mr-93 {
  margin-right: 93px !important; }

.mt-93 {
  margin-top: 93px !important; }

.mb-93 {
  margin-bottom: 93px !important; }

.ml-93 {
  margin-left: 93px !important; }

.mlr-93 {
  margin-left: 93px !important;
  margin-right: 93px !important; }

.mrl-93 {
  margin-left: 93px !important;
  margin-right: 93px !important; }

.mtb-93 {
  margin-top: 93px !important;
  margin-bottom: 93px !important; }

.mbt-93 {
  margin-top: 93px !important;
  margin-bottom: 93px !important; }

.border-radius-94 {
  border-radius: 94px !important; }

.fs-94 {
  font-size: 94px !important; }
  .fs-94.light-text {
    font-family: "montserrat-light"; }
  .fs-94.regular-text {
    font-family: "montserrat-regular"; }
  .fs-94.medium-text {
    font-family: "montserrat-medium"; }
  .fs-94.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-94.bold-text {
    font-family: "montserrat-bold"; }

.pa-94 {
  padding: 94px !important; }

.pr-94 {
  padding-right: 94px !important; }

.pl-94 {
  padding-left: 94px !important; }

.pt-94 {
  padding-top: 94px !important; }

.pb-94 {
  padding-bottom: 94px !important; }

.plr-94 {
  padding-left: 94px !important;
  padding-right: 94px !important; }

.prl-94 {
  padding-top: 94px !important;
  padding-bottom: 94px !important; }

.ptb-94 {
  padding-top: 94px !important;
  padding-bottom: 94px !important; }

.pbt-94 {
  padding-top: 94px !important;
  padding-bottom: 94px !important; }

.ma-94 {
  margin: 94px !important; }

.mr-94 {
  margin-right: 94px !important; }

.mt-94 {
  margin-top: 94px !important; }

.mb-94 {
  margin-bottom: 94px !important; }

.ml-94 {
  margin-left: 94px !important; }

.mlr-94 {
  margin-left: 94px !important;
  margin-right: 94px !important; }

.mrl-94 {
  margin-left: 94px !important;
  margin-right: 94px !important; }

.mtb-94 {
  margin-top: 94px !important;
  margin-bottom: 94px !important; }

.mbt-94 {
  margin-top: 94px !important;
  margin-bottom: 94px !important; }

.border-radius-95 {
  border-radius: 95px !important; }

.fs-95 {
  font-size: 95px !important; }
  .fs-95.light-text {
    font-family: "montserrat-light"; }
  .fs-95.regular-text {
    font-family: "montserrat-regular"; }
  .fs-95.medium-text {
    font-family: "montserrat-medium"; }
  .fs-95.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-95.bold-text {
    font-family: "montserrat-bold"; }

.pa-95 {
  padding: 95px !important; }

.pr-95 {
  padding-right: 95px !important; }

.pl-95 {
  padding-left: 95px !important; }

.pt-95 {
  padding-top: 95px !important; }

.pb-95 {
  padding-bottom: 95px !important; }

.plr-95 {
  padding-left: 95px !important;
  padding-right: 95px !important; }

.prl-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important; }

.ptb-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important; }

.pbt-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important; }

.ma-95 {
  margin: 95px !important; }

.mr-95 {
  margin-right: 95px !important; }

.mt-95 {
  margin-top: 95px !important; }

.mb-95 {
  margin-bottom: 95px !important; }

.ml-95 {
  margin-left: 95px !important; }

.mlr-95 {
  margin-left: 95px !important;
  margin-right: 95px !important; }

.mrl-95 {
  margin-left: 95px !important;
  margin-right: 95px !important; }

.mtb-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important; }

.mbt-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important; }

.border-radius-96 {
  border-radius: 96px !important; }

.fs-96 {
  font-size: 96px !important; }
  .fs-96.light-text {
    font-family: "montserrat-light"; }
  .fs-96.regular-text {
    font-family: "montserrat-regular"; }
  .fs-96.medium-text {
    font-family: "montserrat-medium"; }
  .fs-96.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-96.bold-text {
    font-family: "montserrat-bold"; }

.pa-96 {
  padding: 96px !important; }

.pr-96 {
  padding-right: 96px !important; }

.pl-96 {
  padding-left: 96px !important; }

.pt-96 {
  padding-top: 96px !important; }

.pb-96 {
  padding-bottom: 96px !important; }

.plr-96 {
  padding-left: 96px !important;
  padding-right: 96px !important; }

.prl-96 {
  padding-top: 96px !important;
  padding-bottom: 96px !important; }

.ptb-96 {
  padding-top: 96px !important;
  padding-bottom: 96px !important; }

.pbt-96 {
  padding-top: 96px !important;
  padding-bottom: 96px !important; }

.ma-96 {
  margin: 96px !important; }

.mr-96 {
  margin-right: 96px !important; }

.mt-96 {
  margin-top: 96px !important; }

.mb-96 {
  margin-bottom: 96px !important; }

.ml-96 {
  margin-left: 96px !important; }

.mlr-96 {
  margin-left: 96px !important;
  margin-right: 96px !important; }

.mrl-96 {
  margin-left: 96px !important;
  margin-right: 96px !important; }

.mtb-96 {
  margin-top: 96px !important;
  margin-bottom: 96px !important; }

.mbt-96 {
  margin-top: 96px !important;
  margin-bottom: 96px !important; }

.border-radius-97 {
  border-radius: 97px !important; }

.fs-97 {
  font-size: 97px !important; }
  .fs-97.light-text {
    font-family: "montserrat-light"; }
  .fs-97.regular-text {
    font-family: "montserrat-regular"; }
  .fs-97.medium-text {
    font-family: "montserrat-medium"; }
  .fs-97.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-97.bold-text {
    font-family: "montserrat-bold"; }

.pa-97 {
  padding: 97px !important; }

.pr-97 {
  padding-right: 97px !important; }

.pl-97 {
  padding-left: 97px !important; }

.pt-97 {
  padding-top: 97px !important; }

.pb-97 {
  padding-bottom: 97px !important; }

.plr-97 {
  padding-left: 97px !important;
  padding-right: 97px !important; }

.prl-97 {
  padding-top: 97px !important;
  padding-bottom: 97px !important; }

.ptb-97 {
  padding-top: 97px !important;
  padding-bottom: 97px !important; }

.pbt-97 {
  padding-top: 97px !important;
  padding-bottom: 97px !important; }

.ma-97 {
  margin: 97px !important; }

.mr-97 {
  margin-right: 97px !important; }

.mt-97 {
  margin-top: 97px !important; }

.mb-97 {
  margin-bottom: 97px !important; }

.ml-97 {
  margin-left: 97px !important; }

.mlr-97 {
  margin-left: 97px !important;
  margin-right: 97px !important; }

.mrl-97 {
  margin-left: 97px !important;
  margin-right: 97px !important; }

.mtb-97 {
  margin-top: 97px !important;
  margin-bottom: 97px !important; }

.mbt-97 {
  margin-top: 97px !important;
  margin-bottom: 97px !important; }

.border-radius-98 {
  border-radius: 98px !important; }

.fs-98 {
  font-size: 98px !important; }
  .fs-98.light-text {
    font-family: "montserrat-light"; }
  .fs-98.regular-text {
    font-family: "montserrat-regular"; }
  .fs-98.medium-text {
    font-family: "montserrat-medium"; }
  .fs-98.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-98.bold-text {
    font-family: "montserrat-bold"; }

.pa-98 {
  padding: 98px !important; }

.pr-98 {
  padding-right: 98px !important; }

.pl-98 {
  padding-left: 98px !important; }

.pt-98 {
  padding-top: 98px !important; }

.pb-98 {
  padding-bottom: 98px !important; }

.plr-98 {
  padding-left: 98px !important;
  padding-right: 98px !important; }

.prl-98 {
  padding-top: 98px !important;
  padding-bottom: 98px !important; }

.ptb-98 {
  padding-top: 98px !important;
  padding-bottom: 98px !important; }

.pbt-98 {
  padding-top: 98px !important;
  padding-bottom: 98px !important; }

.ma-98 {
  margin: 98px !important; }

.mr-98 {
  margin-right: 98px !important; }

.mt-98 {
  margin-top: 98px !important; }

.mb-98 {
  margin-bottom: 98px !important; }

.ml-98 {
  margin-left: 98px !important; }

.mlr-98 {
  margin-left: 98px !important;
  margin-right: 98px !important; }

.mrl-98 {
  margin-left: 98px !important;
  margin-right: 98px !important; }

.mtb-98 {
  margin-top: 98px !important;
  margin-bottom: 98px !important; }

.mbt-98 {
  margin-top: 98px !important;
  margin-bottom: 98px !important; }

.border-radius-99 {
  border-radius: 99px !important; }

.fs-99 {
  font-size: 99px !important; }
  .fs-99.light-text {
    font-family: "montserrat-light"; }
  .fs-99.regular-text {
    font-family: "montserrat-regular"; }
  .fs-99.medium-text {
    font-family: "montserrat-medium"; }
  .fs-99.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-99.bold-text {
    font-family: "montserrat-bold"; }

.pa-99 {
  padding: 99px !important; }

.pr-99 {
  padding-right: 99px !important; }

.pl-99 {
  padding-left: 99px !important; }

.pt-99 {
  padding-top: 99px !important; }

.pb-99 {
  padding-bottom: 99px !important; }

.plr-99 {
  padding-left: 99px !important;
  padding-right: 99px !important; }

.prl-99 {
  padding-top: 99px !important;
  padding-bottom: 99px !important; }

.ptb-99 {
  padding-top: 99px !important;
  padding-bottom: 99px !important; }

.pbt-99 {
  padding-top: 99px !important;
  padding-bottom: 99px !important; }

.ma-99 {
  margin: 99px !important; }

.mr-99 {
  margin-right: 99px !important; }

.mt-99 {
  margin-top: 99px !important; }

.mb-99 {
  margin-bottom: 99px !important; }

.ml-99 {
  margin-left: 99px !important; }

.mlr-99 {
  margin-left: 99px !important;
  margin-right: 99px !important; }

.mrl-99 {
  margin-left: 99px !important;
  margin-right: 99px !important; }

.mtb-99 {
  margin-top: 99px !important;
  margin-bottom: 99px !important; }

.mbt-99 {
  margin-top: 99px !important;
  margin-bottom: 99px !important; }

.border-radius-100 {
  border-radius: 100px !important; }

.fs-100 {
  font-size: 100px !important; }
  .fs-100.light-text {
    font-family: "montserrat-light"; }
  .fs-100.regular-text {
    font-family: "montserrat-regular"; }
  .fs-100.medium-text {
    font-family: "montserrat-medium"; }
  .fs-100.demi-bold-text {
    font-family: "montserrat-semi-bold"; }
  .fs-100.bold-text {
    font-family: "montserrat-bold"; }

.pa-100 {
  padding: 100px !important; }

.pr-100 {
  padding-right: 100px !important; }

.pl-100 {
  padding-left: 100px !important; }

.pt-100 {
  padding-top: 100px !important; }

.pb-100 {
  padding-bottom: 100px !important; }

.plr-100 {
  padding-left: 100px !important;
  padding-right: 100px !important; }

.prl-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important; }

.ptb-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important; }

.pbt-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important; }

.ma-100 {
  margin: 100px !important; }

.mr-100 {
  margin-right: 100px !important; }

.mt-100 {
  margin-top: 100px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.ml-100 {
  margin-left: 100px !important; }

.mlr-100 {
  margin-left: 100px !important;
  margin-right: 100px !important; }

.mrl-100 {
  margin-left: 100px !important;
  margin-right: 100px !important; }

.mtb-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important; }

.mbt-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important; }

.mlr-auto {
  margin-left: auto !important;
  margin-right: auto !important; }

.ml-auto {
  margin-left: auto !important; }

.text-center {
  text-align: center !important; }

.pos-relative {
  position: relative; }

.headline {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: normal; }

.pull-right {
  float: right; }

.border-none {
  border: 0 !important; }

.vert-middle {
  vertical-align: middle; }

.flex {
  display: flex; }
  .flex.center {
    align-items: center; }

.flex-x {
  display: flex;
  flex-direction: row; }
  .flex-x.align-center {
    align-items: center; }
  .flex-x.center {
    align-items: center;
    justify-content: center; }

.flex-y {
  display: flex;
  flex-direction: column; }
  .flex-y.center {
    align-items: center;
    justify-content: center; }

.space-between {
  justify-content: space-between; }

.flex-0 {
  flex: 0; }

.flex-1 {
  flex: 1;
  min-width: 0;
  min-height: 0; }

.flex-wrap {
  flex-wrap: wrap; }

.full-height {
  height: 100vh; }

.fill-width {
  width: 100% !important; }

.fill-height {
  height: 100% !important; }

.overflow-auto {
  overflow: auto; }

.overflow-hide {
  overflow: hidden; }

.cursor-pointer {
  cursor: pointer; }

.hover-underline:hover {
  text-decoration: underline; }

.with-transition {
  transition: all 0.3s ease-in; }

.hover-border:hover {
  text-decoration: underline; }

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  z-index: 9;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  overflow-x: hidden;
  background-position: center center; }
  .sidebar .sidebar-wrapper {
    position: relative;
    height: calc(100vh - 75px);
    z-index: 4;
    padding-bottom: 30px; }
  .sidebar .nav li {
    width: 100%; }
    .sidebar .nav li .card .card-body a {
      color: #FFFFFF;
      border-radius: 4px;
      margin: 5px 10px 0; }
    .sidebar .nav li > a {
      color: #FFFFFF;
      border-radius: 4px;
      margin: 5px 10px 0; }
  .sidebar .nav .caret {
    top: 24px;
    position: absolute;
    right: 15px; }
  .sidebar .nav .span-shortname {
    font-size: 12px;
    float: left;
    margin-right: 15px;
    line-height: 30px;
    width: 30px;
    text-align: center; }
  .sidebar .nav i {
    font-size: 18px;
    float: left;
    margin-right: 15px;
    line-height: 30px;
    width: 30px;
    text-align: center; }

.sidebar:after, .sidebar:before,
body > .navbar-collapse:after,
body > .navbar-collapse:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2; }

.main-panel {
  background: rgba(203, 203, 210, 0.15);
  position: relative;
  float: right;
  width: calc(100% - 260px);
  height: 100vh; }
  @media (max-width: 991.98px) {
    .main-panel {
      width: 100% !important; } }

@media (max-width: 767.98px) {
  .icon-search {
    width: 190px !important; } }

.main-panel,
.navbar-fixed,
.sidebar,
.sidebar-wrapper,
.logo-wrapper {
  -webkit-transition-property: top, bottom, width;
  -o-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: .2s, .2s, .35s;
  -o-transition-duration: .2s, .2s, .35s;
  transition-duration: .2s, .2s, .35s;
  -webkit-transition-timing-function: linear, linear, ease;
  -o-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch; }

.sidebar-overlay,
.themesetting-overlay {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  opacity: 1;
  transition: opacity 1s;
  width: 100%;
  height: 100%; }

.hor_mobile_sidebar {
  display: none; }
  @media (max-width: 991.98px) {
    .hor_mobile_sidebar {
      display: block; } }

.hor_menu {
  max-width: 100% !important; }
  @media (max-width: 991.98px) {
    .hor_menu {
      display: none; } }
  .hor_menu .popover {
    max-width: 100% !important; }
  .hor_menu .menu_section {
    min-width: 150px; }
    .hor_menu .menu_section .hor_heading {
      padding: 15px 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
      .hor_menu .menu_section .hor_heading span {
        font-size: 16px;
        font-family: "montserrat-semi-bold"; }
      @media (min-width: 767px) and (max-width: 991.98px) {
        .hor_menu .menu_section .hor_heading {
          padding: 8px; }
          .hor_menu .menu_section .hor_heading span {
            font-size: 14px; } }
  .hor_menu .hor_nav-link {
    padding: 10px;
    display: block;
    color: black;
    border-radius: 6px;
    transition: all .3s ease-in; }
    .hor_menu .hor_nav-link span {
      font-size: 14px !important;
      color: #6c757d;
      font-family: "montserrat-medium"; }
    .hor_menu .hor_nav-link:hover {
      text-decoration: none; }
      .hor_menu .hor_nav-link:hover span {
        font-family: "montserrat-bold"; }
    .hor_menu .hor_nav-link.active {
      text-decoration: none;
      background-color: #F4F5F7; }
      .hor_menu .hor_nav-link.active span {
        font-family: "montserrat-bold"; }
    @media (min-width: 767px) and (max-width: 991.98px) {
      .hor_menu .hor_nav-link {
        padding: 8px; }
        .hor_menu .hor_nav-link span {
          font-size: 12px; } }

.text-upper {
  text-transform: uppercase; }

@font-face {
  font-family: 'montserrat-light';
  src: url("./../font/Montserrat-Light.ttf");
  /* IE9 Compat Modes */
  src: url("./../font/Montserrat-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'montserrat-regular';
  src: url("./../font/Montserrat-Regular.ttf");
  /* IE9 Compat Modes */
  src: url("./../font/Montserrat-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'montserrat-medium';
  src: url("./../font/Montserrat-Medium.ttf");
  /* IE9 Compat Modes */
  src: url("./../font/Montserrat-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'montserrat-semi-bold';
  src: url("./../font/Montserrat-SemiBold.ttf");
  /* IE9 Compat Modes */
  src: url("./../font/Montserrat-SemiBold.ttf") format("truetype"); }

@font-face {
  font-family: 'montserrat-bold';
  src: url("./../font/Montserrat-Bold.ttf");
  /* IE9 Compat Modes */
  src: url("./../font/Montserrat-Bold.ttf") format("truetype"); }

.form-container {
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
  border-radius: 6px;
  background-color: white;
  width: 320px;
  margin: 6% auto; }
  .form-container .login-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    letter-spacing: normal !important;
    text-align: center; }
  .form-container .link-label {
    font-weight: bold;
    font-size: 14px;
    margin-top: 10px;
    cursor: pointer;
    color: #673ab7; }
    .form-container .link-label:hover {
      text-decoration: underline; }
  .form-container .login-icon {
    padding: 16px 0;
    text-align: center; }
  .form-container .form-button {
    font-size: 18px;
    text-transform: capitalize;
    box-shadow: none !important;
    width: 100%;
    border-radius: 2px;
    font-weight: 500;
    background: #00c486;
    color: white; }
  .form-container .form-info-text {
    color: #677484;
    font-size: 15px; }
  .form-container .register-privacy-text {
    font-size: 14px; }
  .form-container .lock-screen-profile {
    padding-bottom: 0px; }
    .form-container .lock-screen-profile img {
      border-radius: 50%; }

.react-form-input {
  border-radius: 6px !important;
  font-size: 14px !important;
  border: 1px solid #ddd !important; }
  .react-form-input:focus {
    border: 1px solid #9a9a9a !important;
    box-shadow: none !important; }

.react-form-search-input {
  font-size: 14px !important;
  border: 1px solid #ddd !important; }
  .react-form-search-input:focus {
    border: 1px solid #9a9a9a !important;
    box-shadow: none !important; }

.static-control-input {
  border-radius: 6px !important;
  font-size: 14px !important; }
  .static-control-input:focus {
    outline: 0;
    box-shadow: none !important; }

.form-button {
  font-size: 18px;
  text-transform: capitalize;
  box-shadow: none !important;
  width: 100%;
  border-radius: 2px;
  font-weight: 500;
  background: #00c486;
  color: white; }

.error-msg {
  font-size: 12px;
  color: red;
  font-weight: 600; }

.page-home-button {
  color: #fff;
  cursor: pointer;
  padding: 20px 25px;
  border-radius: 2px;
  font-size: 14px;
  border: 0;
  float: right;
  background-color: transparent; }
  .page-home-button:hover {
    background-color: rgba(0, 0, 0, 0.12); }

.pricing-page .Pricing-title {
  font-size: 45px;
  line-height: 48px;
  letter-spacing: normal;
  color: #fff;
  font-weight: 500;
  margin: 48px 0; }

.pricing-page .pricing-card {
  max-width: 320px;
  margin: 0 auto;
  text-align: center;
  color: white;
  margin-bottom: 30px;
  font-weight: 600;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12); }
  .pricing-page .pricing-card .pink-highlight {
    background-color: #f06292;
    padding: 10px 0;
    margin-bottom: 20px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(240, 98, 146, 0.6); }
  .pricing-page .pricing-card .pricing-button {
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 2px;
    border: 0;
    color: white;
    background-color: #6200ea;
    cursor: pointer;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .pricing-page .pricing-card .trial-info {
    color: #00bcd4 !important; }

.pricing-page .pricing-white-card {
  max-width: 320px;
  margin: 0 auto;
  margin-bottom: 30px;
  text-align: center;
  color: black;
  font-weight: 600;
  background-color: #fff !important;
  box-shadow: 0 10px 13px -6px rgba(0, 0, 0, 0.2), 0 20px 31px 3px rgba(0, 0, 0, 0.14), 0 8px 38px 7px rgba(0, 0, 0, 0.12) !important; }
  .pricing-page .pricing-white-card .pink-highlight {
    color: white;
    background-color: #f06292;
    padding: 10px 0;
    margin-bottom: 20px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(240, 98, 146, 0.6); }
  .pricing-page .pricing-white-card .pricing-button {
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 2px;
    border: 0;
    color: white;
    background-color: #6200ea;
    cursor: pointer;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .pricing-page .pricing-white-card .trial-info {
    color: #00bcd4 !important; }

.pricing-page .style-2-price-round-title-white {
  background: white;
  color: #6200ea; }

.pricing-page .style-2-price-round-title {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 auto; }

.pricing-page .style-2-price-round-title-dark {
  background: #6200ea;
  color: white; }

.pricing-page .faq-pricing {
  color: white;
  font-weight: 600; }
  .pricing-page .faq-pricing .pricing-faq-card {
    background-color: #fff;
    padding: 40px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 30px; }
    .pricing-page .faq-pricing .pricing-faq-card .title {
      font-size: 20px !important;
      font-weight: 600;
      line-height: 1 !important;
      letter-spacing: 0.02em !important; }
    .pricing-page .faq-pricing .pricing-faq-card .description {
      font-size: 14px;
      font-style: normal;
      font-weight: 500; }

.chip {
  background-color: #f5f5f5;
  color: #bd4147;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  display: inline-block;
  border-radius: 3px;
  white-space: pre-wrap;
  font-size: 85%;
  font-weight: 900;
  padding: 0 10px; }

.introduction {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600; }

.chart-container .sub-heading {
  font-size: 16px !important;
  font-weight: 400;
  margin-top: 10px; }

.chart-container .chart-discription {
  font-size: 14px;
  margin: 10px 0;
  font-weight: 500; }

.chart-container .chart-box {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
  background: #fafafa;
  padding: 30px 0;
  background: white; }

.react-action-class button {
  background: transparent;
  border: 0;
  cursor: pointer;
  height: 1.875rem;
  font-size: .9375rem;
  width: 1.875rem;
  border-radius: 3px;
  margin-right: 5px; }
  .react-action-class button:focus {
    outline: 0 !important; }

.react-action-class .react-table-view-button {
  color: #6bd098; }
  .react-action-class .react-table-view-button:hover {
    background-color: #6bd098;
    color: white; }

.react-action-class .react-table-edit-button {
  color: #fab526; }
  .react-action-class .react-table-edit-button:hover {
    background-color: #fab526;
    color: white; }

.react-action-class .react-table-delete-button {
  color: #eb6532; }
  .react-action-class .react-table-delete-button:hover {
    background-color: #eb6532;
    color: white; }

.react-table-header-class {
  padding: 14px 0 !important;
  font-weight: 600; }

.c-btn {
  position: relative !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12) !important;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s !important;
  cursor: pointer !important;
  border: 1px solid transparent !important;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  outline: none !important;
  display: inline-block !important;
  text-align: center !important;
  white-space: nowrap !important;
  line-height: 1.5 !important;
  padding: 0.375rem 0.75rem !important;
  border-radius: 6px; }
  .c-btn:focus {
    outline: 0; }

.c-rounded {
  border-radius: 100px !important; }

.no-border {
  border: 0 !important !important; }

.c-btn-round {
  border-radius: 100px !important; }

.c-primary {
  color: #fff !important;
  background-color: #3f6ad8 !important;
  border-color: #3f6ad8 !important; }
  .c-primary:hover {
    color: #fff !important;
    background-color: #2955c8 !important;
    border-color: #2651be !important; }

.c-secondary {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important; }
  .c-secondary:hover {
    color: #fff !important;
    background-color: #5a6268 !important;
    border-color: #545b62 !important; }

.c-success {
  color: #fff !important;
  background-color: #00c486 !important;
  border-color: #00c486 !important; }
  .c-success:hover {
    color: #fff !important;
    background-color: #31a66a !important;
    border-color: #2e9d64 !important; }

.c-info {
  color: #fff !important;
  background-color: #00bcd4 !important;
  border-color: #00bcd4 !important; }
  .c-info:hover {
    color: #fff !important;
    background-color: #00aec5 !important;
    border-color: #00aec5 !important; }

.c-warning {
  color: #212529 !important;
  background-color: #f7b924 !important;
  border-color: #f7b924 !important; }
  .c-warning:hover {
    color: #212529 !important;
    background-color: #eca909 !important;
    border-color: #e0a008 !important; }

.c-danger {
  color: #fff !important;
  background-color: #ff3279 !important;
  border-color: #ff3279 !important; }
  .c-danger:hover {
    color: #fff !important;
    background-color: #ff3279 !important;
    border-color: #ff3279 !important; }

.c-focus {
  color: #fff !important;
  background-color: #444054 !important;
  border-color: #444054 !important; }
  .c-focus:hover {
    color: #fff !important;
    background-color: #322f3e !important;
    border-color: #2d2a37 !important; }

.c-alternate {
  color: #fff !important;
  background-color: #794c8a !important;
  border-color: #794c8a !important; }
  .c-alternate:hover {
    color: #212529 !important;
    background-color: #dbdbdb !important;
    border-color: #d5d5d5 !important; }

.c-light {
  color: #212529 !important;
  background-color: #eeeeee !important;
  border-color: #eeeeee !important; }
  .c-light:hover {
    color: #212529 !important;
    background-color: #dbdbdb !important;
    border-color: #d5d5d5 !important; }

.c-dark {
  color: #fff !important;
  background-color: #343a40 !important;
  border-color: #343a40 !important; }
  .c-dark:hover {
    color: #fff !important;
    background-color: #23272b !important;
    border-color: #1d2124 !important; }

.c-link {
  font-weight: 400 !important;
  color: #3f6ad8 !important;
  background-color: transparent !important; }
  .c-link:hover {
    color: #0056b3 !important;
    text-decoration: underline !important;
    background-color: transparent !important;
    border-color: transparent !important; }

.c-outline-primary {
  color: #3f6ad8 !important;
  background-color: transparent !important;
  background-image: none !important;
  border-color: #3f6ad8 !important; }
  .c-outline-primary .lds-ring div {
    border-color: #3f6ad8 transparent transparent transparent !important; }
  .c-outline-primary:hover {
    color: #fff !important;
    background-color: #3f6ad8 !important;
    border-color: #3f6ad8 !important; }
    .c-outline-primary:hover .lds-ring div {
      border-color: white transparent transparent transparent !important; }

.c-outline-secondary {
  color: #6c757d !important;
  background-color: transparent !important;
  background-image: none !important;
  border-color: #6c757d !important; }
  .c-outline-secondary .lds-ring div {
    border-color: #6c757d transparent transparent transparent !important; }
  .c-outline-secondary:hover {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important; }
    .c-outline-secondary:hover .lds-ring div {
      border-color: white transparent transparent transparent !important; }

.c-outline-success {
  color: #00c486 !important;
  background-color: transparent !important;
  background-image: none !important;
  border-color: #00c486 !important; }
  .c-outline-success .lds-ring div {
    border-color: #00c486 transparent transparent transparent !important; }
  .c-outline-success:hover {
    color: #fff !important;
    background-color: #00c486 !important;
    border-color: #00c486 !important; }
    .c-outline-success:hover .lds-ring div {
      border-color: white transparent transparent transparent !important; }

.c-outline-info {
  color: #16aaff !important;
  background-color: transparent !important;
  background-image: none !important;
  border-color: #16aaff !important; }
  .c-outline-info .lds-ring div {
    border-color: #16aaff transparent transparent transparent !important; }
  .c-outline-info:hover {
    color: #fff !important;
    background-color: #00aec5 !important;
    border-color: #00aec5 !important; }
    .c-outline-info:hover .lds-ring div {
      border-color: white transparent transparent transparent !important; }

.c-outline-warning {
  color: #f7b924 !important;
  background-color: transparent !important;
  background-image: none !important;
  border-color: #f7b924 !important; }
  .c-outline-warning .lds-ring div {
    border-color: #f7b924 transparent transparent transparent !important; }
  .c-outline-warning:hover {
    color: #212529 !important;
    background-color: #f7b924 !important;
    border-color: #f7b924 !important; }
    .c-outline-warning:hover .lds-ring div {
      border-color: white transparent transparent transparent !important; }

.c-outline-danger {
  color: #ff3279 !important;
  background-color: transparent !important;
  background-image: none !important;
  border-color: #ff3279 !important; }
  .c-outline-danger .lds-ring div {
    border-color: #ff3279 transparent transparent transparent !important; }
  .c-outline-danger:hover {
    color: #fff !important;
    background-color: #ff3279 !important;
    border-color: #ff3279 !important; }
    .c-outline-danger:hover .lds-ring div {
      border-color: white transparent transparent transparent !important; }

.c-outline-focus {
  color: #444054 !important;
  background-color: transparent !important;
  background-image: none !important;
  border-color: #444054 !important; }
  .c-outline-focus .lds-ring div {
    border-color: #444054 transparent transparent transparent !important; }
  .c-outline-focus:hover {
    color: #fff !important;
    background-color: #444054 !important;
    border-color: #444054 !important; }
    .c-outline-focus:hover .lds-ring div {
      border-color: white transparent transparent transparent !important; }

.c-outline-alternate {
  color: #794c8a !important;
  background-color: transparent !important;
  background-image: none !important;
  border-color: #794c8a !important; }
  .c-outline-alternate .lds-ring div {
    border-color: #794c8a transparent transparent transparent !important; }
  .c-outline-alternate:hover {
    color: #fff !important;
    background-color: #794c8a !important;
    border-color: #794c8a !important; }
    .c-outline-alternate:hover .lds-ring div {
      border-color: white transparent transparent transparent !important; }

.c-outline-light {
  color: #8f8f8f !important;
  background-color: transparent !important;
  background-image: none !important;
  border-color: #eeeeee !important; }
  .c-outline-light .lds-ring div {
    border-color: #8f8f8f transparent transparent transparent !important; }
  .c-outline-light:hover {
    color: #212529 !important;
    background-color: #eeeeee !important;
    border-color: #eeeeee !important; }
    .c-outline-light:hover .lds-ring div {
      border-color: #212529 transparent transparent transparent !important; }

.c-outline-dark {
  color: #343a40 !important;
  background-color: transparent !important;
  background-image: none !important;
  border-color: #343a40 !important; }
  .c-outline-dark .lds-ring div {
    border-color: #343a40 transparent transparent transparent !important; }
  .c-outline-dark:hover {
    color: #fff !important;
    background-color: #343a40 !important;
    border-color: #343a40 !important; }
    .c-outline-dark:hover .lds-ring div {
      border-color: #fff transparent transparent transparent !important; }

.c-outline-link {
  border-color: transparent !important; }
  .c-outline-link:hover {
    color: #3f6ad8 !important;
    background: #f8f9fa !important; }

.c-btn.disabled, .c-btn:disabled {
  opacity: 0.65 !important;
  cursor: auto !important; }

.c-btn-lg {
  padding: 0.5rem 1rem !important;
  font-size: 1.1rem !important;
  line-height: 1.5 !important; }

.c-btn-sm {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.8rem !important;
  line-height: 1.5 !important; }

.c-btn-lg.c-btn-wide, .c-btn-group-lg > .c-btn-wide.c-btn {
  padding: 0.5rem 2rem !important;
  font-size: 1.1rem !important;
  line-height: 1.5 !important; }

.expand-left {
  padding-left: 40px !important;
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) all !important; }

.expand-right {
  padding-right: 40px !important;
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) all !important; }

.expand-up {
  padding-top: 30px !important;
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) all !important; }

.expand-down {
  padding-bottom: 30px !important;
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) all !important; }

.loder-down {
  right: 41% !important;
  bottom: 10px !important; }

.loder-left {
  left: 10px !important;
  top: 0 !important; }

.loder-right {
  right: 14px !important;
  top: 0 !important; }

.loder-middle {
  right: 41% !important;
  top: 0 !important; }

.label-middle {
  opacity: 0; }

.lds-ring {
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 1; }

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 8px;
  height: 20px;
  margin: 6px;
  border: 2px solid #fff;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: white transparent transparent transparent; }

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s; }

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s; }

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.c-btn-dashed {
  border-style: dashed !important; }

.c-text-primary {
  color: #3f6ad8; }
  .c-text-primary:hover {
    color: #2955c8; }

.c-text-secondary {
  color: #6c757d; }
  .c-text-secondary:hover {
    color: #5a6268; }

.c-text-success {
  color: #3ac47d; }
  .c-text-success:hover {
    color: #2e9d64; }

.c-text-info {
  color: #16aaff; }
  .c-text-info:hover {
    color: #0098ef; }

.c-text-warning {
  color: #f7b924; }
  .c-text-warning:hover {
    color: #eca909; }

.c-text-danger {
  color: #d92550; }
  .c-text-danger:hover {
    color: #d92550; }

.c-text-focus {
  color: #444054; }
  .c-text-focus:hover {
    color: #322f3e; }

.c-text-alternate {
  color: #794c8a; }

.c-text-dark {
  color: #343a40; }
  .c-text-dark:hover {
    color: #23272b; }

.heading-title {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  color: #fff;
  clear: both; }

.display-info-class {
  font-weight: bolder;
  font-size: 16px; }

.grey--text {
  color: #757575; }

.header-title {
  color: #757575;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px; }

.display-5 {
  font-size: 1.14rem;
  font-weight: 300; }

.display-6 {
  font-size: 1rem;
  font-weight: 300; }

.subheading {
  font-size: 16px;
  font-weight: 400; }

.body-2 {
  font-size: 14px;
  font-weight: 500; }

.reactstrap-example-block {
  max-width: 400px;
  margin: 0 auto; }

.reactstrap-badge-button-block button {
  margin: 0 10px 10px 0; }

.reactstrap-button-block button {
  margin: 0 10px 10px 0; }

.reactstrap-badge-block .badge {
  margin: 0 10px 10px 0; }

.reactstrap-breadcrumb-block {
  display: flex;
  flex-flow: wrap; }
  .reactstrap-breadcrumb-block .breadcrumb {
    margin-right: 10px; }

.reactstrap-splitbutton-drop-block .btn-group {
  margin: 0 10px 10px 0; }

.reactstrap-dropdown-block button {
  margin: 0 10px 10px 0; }

.button-toolbar-block .btn-group {
  margin: 0 10px 10px 0; }

.reactstrap-card-block .card {
  max-width: 300px;
  margin: 0 auto; }
  @media (max-width: 575.98px) {
    .reactstrap-card-block .card {
      max-width: 100%; } }

.card-alignment-block .card {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 10px; }

.list-group-block {
  max-width: 300px;
  margin: 0 auto; }

.reactstrap-media {
  overflow: auto; }
  .reactstrap-media img {
    width: 200px; }
  .reactstrap-media .media-body {
    padding-left: 10px; }
  .reactstrap-media .media-list {
    padding-left: 0; }

.progress-block .progress {
  margin-bottom: 10px; }

.label1 {
  background: #77A361;
  background-color: #77A361 !important;
  border-color: #77A361 !important; }

.label1--text {
  color: #77A361 !important; }

.label1-c-hover:hover {
  color: #77A361 !important; }

.label1-b-hover:hover {
  background-color: #77A361 !important; }

.label2 {
  background: #CD6889;
  background-color: #CD6889 !important;
  border-color: #CD6889 !important; }

.label2--text {
  color: #CD6889 !important; }

.label2-c-hover:hover {
  color: #CD6889 !important; }

.label2-b-hover:hover {
  background-color: #CD6889 !important; }

.label3 {
  background: #2F4F4F;
  background-color: #2F4F4F !important;
  border-color: #2F4F4F !important; }

.label3--text {
  color: #2F4F4F !important; }

.label3-c-hover:hover {
  color: #2F4F4F !important; }

.label3-b-hover:hover {
  background-color: #2F4F4F !important; }

.label4 {
  background: #104E8B;
  background-color: #104E8B !important;
  border-color: #104E8B !important; }

.label4--text {
  color: #104E8B !important; }

.label4-c-hover:hover {
  color: #104E8B !important; }

.label4-b-hover:hover {
  background-color: #104E8B !important; }

.label5 {
  background: #A020F0;
  background-color: #A020F0 !important;
  border-color: #A020F0 !important; }

.label5--text {
  color: #A020F0 !important; }

.label5-c-hover:hover {
  color: #A020F0 !important; }

.label5-b-hover:hover {
  background-color: #A020F0 !important; }

.label6 {
  background: #FF8000;
  background-color: #FF8000 !important;
  border-color: #FF8000 !important; }

.label6--text {
  color: #FF8000 !important; }

.label6-c-hover:hover {
  color: #FF8000 !important; }

.label6-b-hover:hover {
  background-color: #FF8000 !important; }

.taskboardicons {
  background: #6c6689;
  background-color: #6c6689 !important;
  border-color: #6c6689 !important; }

.taskboardicons--text {
  color: #6c6689 !important; }

.taskboardicons-c-hover:hover {
  color: #6c6689 !important; }

.taskboardicons-b-hover:hover {
  background-color: #6c6689 !important; }

.success {
  background: #57c4a7;
  background-color: #57c4a7 !important;
  border-color: #57c4a7 !important; }

.success--text {
  color: #57c4a7 !important; }

.success-c-hover:hover {
  color: #57c4a7 !important; }

.success-b-hover:hover {
  background-color: #57c4a7 !important; }

.whitelight {
  background: #FFF;
  background-color: #FFF !important;
  border-color: #FFF !important; }

.whitelight--text {
  color: #FFF !important; }

.whitelight-c-hover:hover {
  color: #FFF !important; }

.whitelight-b-hover:hover {
  background-color: #FFF !important; }

.lightGrey {
  background: #F4F5F7;
  background-color: #F4F5F7 !important;
  border-color: #F4F5F7 !important; }

.lightGrey--text {
  color: #F4F5F7 !important; }

.lightGrey-c-hover:hover {
  color: #F4F5F7 !important; }

.lightGrey-b-hover:hover {
  background-color: #F4F5F7 !important; }

.greytext {
  background: #6c757d;
  background-color: #6c757d !important;
  border-color: #6c757d !important; }

.greytext--text {
  color: #6c757d !important; }

.greytext-c-hover:hover {
  color: #6c757d !important; }

.greytext-b-hover:hover {
  background-color: #6c757d !important; }

.lighttext {
  background: #575d78;
  background-color: #575d78 !important;
  border-color: #575d78 !important; }

.lighttext--text {
  color: #575d78 !important; }

.lighttext-c-hover:hover {
  color: #575d78 !important; }

.lighttext-b-hover:hover {
  background-color: #575d78 !important; }

@media (min-width: 768px) and (max-width: 991.98px) {
  .task-dialog {
    max-width: 100% !important;
    margin-left: 20px !important;
    margin-right: 20px !important; } }

.task-dialog .task-dialog-header {
  display: flex; }
  @media (max-width: 575.98px) {
    .task-dialog .task-dialog-header {
      flex-direction: column-reverse; } }
  .task-dialog .task-dialog-header .task-title {
    font-size: 20px;
    flex: 1;
    color: #42526e; }
  @media (max-width: 575.98px) {
    .task-dialog .task-dialog-header .icons {
      text-align: right; } }
  .task-dialog .task-dialog-header .icons i {
    color: #42526e;
    margin: 0 10px;
    cursor: pointer; }

.task-dialog .task-dialog-content {
  background-color: #F4F5F7; }
  .task-dialog .task-dialog-content .close {
    display: none; }
  .task-dialog .task-dialog-content .modal-body {
    padding-top: 0; }
  .task-dialog .task-dialog-content .modal-header {
    border-bottom: 0; }
    .task-dialog .task-dialog-content .modal-header .modal-title {
      width: 100%; }

.task-dialog .task-description {
  background: white;
  padding: 15px;
  border-radius: 7px;
  cursor: pointer; }

.task-dialog .task-attachment {
  background-color: white;
  padding: 10px;
  border-radius: 6px;
  margin-left: 10px;
  margin-top: 10px;
  max-width: 220px; }
  @media (max-width: 575.98px) {
    .task-dialog .task-attachment {
      margin-left: 0px;
      max-width: 100%; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .task-dialog .task-attachment {
      max-width: 100%;
      margin-left: 0px; } }
  .task-dialog .task-attachment .title {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px; }
  .task-dialog .task-attachment .attachment-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .task-dialog .task-attachment .attachment-group .attachment {
      margin: 8px 8px 8px 0;
      background: white;
      padding: 15px;
      border-radius: 7px;
      position: relative;
      background-color: rgba(9, 30, 66, 0.04);
      margin: 5px auto; }
      .task-dialog .task-attachment .attachment-group .attachment .more_icon i {
        text-align: right;
        position: absolute;
        top: 0px;
        right: 6px;
        cursor: pointer; }
      .task-dialog .task-attachment .attachment-group .attachment .file-name {
        font-size: 12px;
        font-weight: 400;
        color: #1d2b3b;
        text-align: center;
        margin-top: 8px; }
      .task-dialog .task-attachment .attachment-group .attachment img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 7px; }

.task-dialog .left-side {
  flex: 1; }

.task-dialog .dialog-flex {
  display: flex; }
  @media (max-width: 575.98px) {
    .task-dialog .dialog-flex {
      display: block; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .task-dialog .dialog-flex {
      display: block; } }

.task-dialog .sub-task {
  background: white;
  padding: 0 15px 15px;
  border-radius: 6px; }
  .task-dialog .sub-task .subtask-search {
    font-size: 12px;
    padding: 4px 10px;
    color: #42526e;
    border-radius: 6px;
    border: 1px solid rgba(9, 30, 66, 0.2); }
  .task-dialog .sub-task .subtask-block-header {
    display: flex;
    align-items: center; }
    .task-dialog .sub-task .subtask-block-header i {
      cursor: pointer;
      margin-left: 10px;
      color: #42526e; }
  .task-dialog .sub-task .title {
    flex: 1;
    font-size: 18px;
    font-weight: 800;
    padding: 15px 0; }
    @media (max-width: 575.98px) {
      .task-dialog .sub-task .title {
        font-size: 14px; } }
    .task-dialog .sub-task .title i {
      margin-right: 15px; }
  .task-dialog .sub-task .subtask-detail {
    background-color: rgba(9, 30, 66, 0.04);
    padding: 10px;
    border-radius: 6px;
    font-size: 14px;
    margin-bottom: 10px; }
    .task-dialog .sub-task .subtask-detail .subtask-options {
      display: flex;
      align-items: center; }
      .task-dialog .sub-task .subtask-detail .subtask-options .comment-count {
        flex: 1;
        font-size: 12px;
        color: #42526e;
        text-decoration: underline;
        cursor: pointer; }
    .task-dialog .sub-task .subtask-detail i {
      color: #42526e;
      margin-left: 10px;
      cursor: pointer;
      font-size: 16px; }
    .task-dialog .sub-task .subtask-detail .sub-task-ticket {
      background-color: #ff3279;
      color: white;
      padding: 2px 8px;
      border-radius: 6px;
      font-size: 12px;
      font-weight: 800; }
    .task-dialog .sub-task .subtask-detail .comment-section {
      padding-top: 10px; }
      .task-dialog .sub-task .subtask-detail .comment-section .comment-detail {
        display: flex;
        margin-bottom: 10px; }
        .task-dialog .sub-task .subtask-detail .comment-section .comment-detail .profile img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover; }
        .task-dialog .sub-task .subtask-detail .comment-section .comment-detail .users {
          padding-left: 10px; }
          .task-dialog .sub-task .subtask-detail .comment-section .comment-detail .users .user-name {
            font-size: 15px;
            font-weight: 600;
            color: #42526e; }
            .task-dialog .sub-task .subtask-detail .comment-section .comment-detail .users .user-name .time {
              margin-left: 8px;
              font-size: 10px;
              font-weight: 300; }
          .task-dialog .sub-task .subtask-detail .comment-section .comment-detail .users .message {
            font-size: 12px;
            background-color: white;
            padding: 5px 10px;
            border-radius: 6px;
            margin-top: 5px;
            display: inline-block; }

.task-dialog .member-block {
  background-color: white;
  padding: 10px;
  border-radius: 6px;
  margin-left: 10px; }
  @media (max-width: 575.98px) {
    .task-dialog .member-block {
      margin-left: 0px;
      margin-top: 10px; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .task-dialog .member-block {
      margin-left: 0px;
      margin-top: 10px; } }
  .task-dialog .member-block .member {
    margin: 5px; }
    .task-dialog .member-block .member img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover; }
  .task-dialog .member-block .all-member {
    display: flex;
    align-items: center;
    max-width: 200px;
    flex-wrap: wrap; }
    @media (max-width: 575.98px) {
      .task-dialog .member-block .all-member {
        max-width: 100%; } }

.task-dialog .dialog-chip {
  background-color: rgba(9, 30, 66, 0.04);
  border-radius: 6px;
  padding: 6px 12px;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  max-width: 115px;
  margin: 0 auto;
  text-align: center;
  border: 0;
  outline: 0; }

.add-task-block, .add-comment-block, .add-subtask-block {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 0 10px 10px;
  color: #6b778c;
  cursor: pointer; }
  .add-task-block i, .add-comment-block i, .add-subtask-block i {
    margin-right: 10px; }

.add-subtask-block, .add-comment-block {
  padding: 0 10px 0px; }

.add-card-input-block, .add-comment-input-block {
  text-align: center; }
  .add-card-input-block .add-card-input, .add-card-input-block .add-comment-input, .add-card-input-block .add-subtask-input, .add-comment-input-block .add-card-input, .add-comment-input-block .add-comment-input, .add-comment-input-block .add-subtask-input {
    width: 93%;
    resize: none;
    border-radius: 3px;
    border: 0;
    font-size: 16px;
    padding: 8px; }
    .add-card-input-block .add-card-input:focus, .add-card-input-block .add-comment-input:focus, .add-card-input-block .add-subtask-input:focus, .add-comment-input-block .add-card-input:focus, .add-comment-input-block .add-comment-input:focus, .add-comment-input-block .add-subtask-input:focus {
      outline: 0; }
  .add-card-input-block .add-comment-input, .add-card-input-block .add-subtask-input, .add-comment-input-block .add-comment-input, .add-comment-input-block .add-subtask-input {
    width: 100% !important;
    margin-top: 10px; }
  .add-card-input-block .add-subtask-input, .add-comment-input-block .add-subtask-input {
    background-color: rgba(9, 30, 66, 0.04);
    margin-top: 0px; }
  .add-card-input-block .action, .add-card-input-block .comment-action, .add-card-input-block .subtask-action, .add-comment-input-block .action, .add-comment-input-block .comment-action, .add-comment-input-block .subtask-action {
    display: flex;
    align-items: center;
    margin-left: 8px;
    margin-bottom: 10px; }
  .add-card-input-block .comment-action, .add-card-input-block .subtask-action, .add-comment-input-block .comment-action, .add-comment-input-block .subtask-action {
    margin-left: 0px;
    margin-bottom: 0px; }

.label-block {
  background-color: white;
  padding: 10px;
  border-radius: 6px;
  margin-left: 10px;
  margin-top: 10px;
  max-width: 220px; }
  @media (max-width: 575.98px) {
    .label-block {
      margin-left: 0px;
      max-width: 100%; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .label-block {
      max-width: 100%;
      margin-left: 0px; } }
  .label-block .heading {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px; }
  .label-block .all-labels {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  .label-block .label {
    padding: 8px 23px;
    border-radius: 6px;
    color: white;
    font-size: 12px;
    margin-right: 10px;
    margin-bottom: 10px; }

.no-subtask-info {
  text-align: center;
  padding: 65px 0 80px;
  font-size: 16px;
  color: #42526e; }
  .no-subtask-info i {
    font-size: 24px;
    color: #00C584; }

.description-input {
  width: 100%;
  border: 0;
  outline: 0; }

.label-rect {
  width: 30px;
  height: 30px;
  border-radius: 7px; }

.label-list {
  display: flex;
  align-items: center; }

.add-member-content {
  max-height: 200px;
  overflow: auto; }

.add-member-list {
  display: flex;
  align-items: center; }
  .add-member-list .member-pics img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px; }

::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

::-webkit-scrollbar-thumb {
  min-width: 70px;
  min-height: 70px;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.13); }

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.23); }

::-webkit-scrollbar-track {
  background: transparent; }

.scroll-dark::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.13); }
  .scroll-dark::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.23); }

.scroll-overlay {
  overflow: overlay; }
  .scroll-overlay::-webkit-scrollbar {
    display: none; }
  .scroll-overlay:hover::-webkit-scrollbar {
    display: initial; }

.wysiwyg-editor {
  box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03); }
  .wysiwyg-editor .css-baehas-Wrapper {
    z-index: 9; }
  .wysiwyg-editor .css-1nnvtu-StyledEditor-StyledEditor {
    min-height: 300px !important; }

.roe-mark-down-editor {
  box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: 10px;
  padding: 15px; }
  .roe-mark-down-editor:focus {
    outline: 0; }

.emojji-picker {
  max-width: 100%;
  padding: 15px;
  max-height: 200px;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03); }
  .emojji-picker .emoji-list {
    display: grid;
    grid-template-columns: repeat(14, 1fr); }
    .emojji-picker .emoji-list .active {
      background-color: #F4F5F7;
      border-radius: 6px; }

.mention-picker {
  max-width: 250px;
  padding: 15px;
  max-height: 200px;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03); }
  .mention-picker .user-list img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px; }
  .mention-picker .user-list .active {
    background-color: #F4F5F7;
    border-radius: 6px; }

.preview-section {
  background-color: white;
  box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03); }

span.mention {
  background: #F4F5F7;
  padding: 2px 4px;
  margin: -2px 2px;
  display: inline-block;
  color: #2D2743;
  cursor: pointer;
  border-radius: 6px; }
  span.mention:before {
    content: '@';
    color: #2D2743; }

.editor-emoji {
  font-size: 20px; }

.bullet:before {
  content: '•';
  margin: 0 10px; }

.bullet {
  display: flex; }

.editor-prototype {
  line-height: 1.5em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: none; }
  .editor-prototype a {
    color: #1f89fc !important;
    text-decoration: none; }
  .editor-prototype p:first-child {
    margin: 8px 0 0 0; }
  .editor-prototype p:not(:first-child) {
    margin: 0; }
  .editor-prototype strong {
    font-family: 'montserrat-bold'; }

.quill-editor .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc; }

/* Snow Theme */
.quill-editor .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em; }

/* Bubble Theme */
.quill-editor .ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em; }

.quill-editor .ql-editor {
  min-height: 18em; }

.themeSwitcher {
  margin-top: 0.5em;
  font-size: small; }

.for-editor .for-container .for-controlbar, .for-editor ul {
  flex-wrap: wrap; }

body {
  overflow: hidden; }

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: 'montserrat-regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004); }

.setting-button {
  position: fixed;
  top: 50%;
  z-index: 3;
  right: 0;
  box-shadow: none;
  font-size: 20px !important;
  padding: 10px !important; }

.arrow-sidebar {
  top: 12px;
  position: absolute;
  right: 5px;
  font-size: 14px !important;
  z-index: 1;
  cursor: pointer; }

.Page-title {
  display: flex;
  align-items: center;
  text-transform: uppercase; }
  .Page-title .title-icon {
    width: 45px;
    height: 45px;
    font-weight: 600;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 6px; }
  .Page-title .title {
    margin-left: 14px;
    font-size: larger;
    font-weight: 600; }

.error-back {
  position: fixed;
  overflow: auto;
  top: 0;
  bottom: 0; }

.error-container {
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
  text-align: center;
  padding: 20% 0;
  width: 100%;
  margin: 10% 0; }
  .error-container .error-heading {
    font-size: 60px !important;
    font-weight: 200;
    line-height: 1 !important;
    letter-spacing: -0.04em !important;
    color: white; }
  .error-container .error-sub-title {
    font-size: 16px !important;
    font-weight: 400;
    color: white;
    padding: 20px 0; }
  .error-container .goHome {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    background-color: #673ab7 !important;
    border-color: #673ab7 !important;
    color: white;
    padding: 9px 16px;
    border-radius: 30px;
    cursor: pointer;
    outline: 0; }

select.selectpicker {
  display: block;
  margin: 0 auto;
  padding-left: 20px; }

.btn-new {
  background-color: #2A3F54; }

.global-hash-title {
  font-size: 20px;
  font-weight: 600;
  margin: 30px 0; }

.sweet-alert-confirm-button {
  color: #fff !important;
  background-color: #3f6ad8 !important;
  border-color: #3f6ad8 !important;
  padding: 10px 60px !important; }

.sweet-alert-cancle-button {
  color: #fff !important;
  background-color: #d92550 !important;
  border-color: #d92550 !important;
  padding: 10px 60px !important; }

.sweet-alert-wrapper .lead {
  margin: 10px 0; }

.sweet-alert-wrapper input {
  border-radius: 6px !important;
  font-size: 14px !important;
  border: 1px solid #ddd !important; }
  .sweet-alert-wrapper input:focus {
    border: 1px solid #9a9a9a !important;
    box-shadow: none !important; }

.icon-component {
  background: #eceff1; }
  .icon-component .icon {
    font-size: 36px;
    color: rgba(0, 0, 0, 0.54); }
  .icon-component .text {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px; }

.profile-image img {
  height: 100px;
  width: 100px;
  border-radius: 50%; }

.Profile-component {
  margin: -15px; }
  .Profile-component .background {
    height: 250px; }
  .Profile-component .send-req-btn {
    position: absolute !important;
    top: 10px;
    right: 25px; }
  .Profile-component .profile-left-shade {
    position: relative;
    margin-top: -52px; }
  .Profile-component .profile-right-shade {
    background: white;
    position: relative;
    top: -25px; }
  .Profile-component .profile-list {
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 10px;
    margin-right: 25px;
    cursor: pointer; }
  .Profile-component .feed-text-area {
    display: block;
    border-radius: 2px;
    min-width: 0;
    position: relative;
    text-decoration: none;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    background-color: #eee;
    border-color: #eee;
    padding: 16px; }
    .Profile-component .feed-text-area textarea {
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      background: #fff; }
      .Profile-component .feed-text-area textarea:focus {
        border-color: white; }
    .Profile-component .feed-text-area .feed-text-area-icon i {
      font-size: 20px;
      margin-right: 15px; }
  .Profile-component .feed-card {
    margin: 0 auto;
    padding: 15px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12); }
    .Profile-component .feed-card .feed-card-header .feed-card-list-image img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-top: -26px; }
    .Profile-component .feed-card .feed-card-header .feed-post-name {
      font-size: 15px;
      font-weight: bold; }
    .Profile-component .feed-card .feed-card-header .feed-post-time {
      font-size: 12px;
      font-weight: 300; }
    .Profile-component .feed-card .feed-card-body .body-description {
      font-size: 14px;
      font-weight: 300; }
    .Profile-component .feed-card .feed-card-body .feed-card-media img {
      width: 100%;
      height: 350px; }
  .Profile-component .Work-header {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 15px 15px;
    align-items: center; }
  .Profile-component .work-card {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    margin: 10px 0; }
    .Profile-component .work-card .work-body .Work-title {
      font-size: 15px;
      font-weight: 600; }
    .Profile-component .work-card .work-body .Work-text {
      font-size: 13px;
      font-weight: 300; }
  .Profile-component .see-all-btn {
    font-size: 12px;
    cursor: pointer; }
  .Profile-component .friend-image {
    width: 100%;
    object-fit: cover; }
  .Profile-component .profile-media-card {
    background-color: #f5f5f5 !important;
    padding: 25px; }
    .Profile-component .profile-media-card .media-image {
      height: 200px;
      width: 100%;
      object-fit: cover; }
    .Profile-component .profile-media-card .media-text {
      position: absolute;
      top: 40%;
      text-align: center;
      width: 88%;
      color: white;
      font-weight: bold; }

.header-popover-width {
  min-width: 25rem; }
  .header-popover-width .custom-popover-header {
    font-weight: 600;
    padding: 20px !important; }

.language-popover-width {
  min-width: 15rem; }
  .language-popover-width .custom-popover-header {
    font-weight: 600;
    padding: 20px !important; }
  .language-popover-width .language-list-hover {
    cursor: pointer; }
    .language-popover-width .language-list-hover:hover {
      background: #fafafa; }

.notification-popover-profile img {
  width: 30px;
  height: 30px;
  border-radius: 50%; }

.bs-popover-auto[x-placement^=bottom] .arrow, .bs-popover-bottom .arrow {
  top: calc((.5rem + 0px) * -1) !important; }

.bs-popover-auto[x-placement^=bottom] .arrow::after, .bs-popover-bottom .arrow::after {
  top: 1px; }

.bs-popover-auto[x-placement^=bottom] .popover-header::before, .bs-popover-bottom .popover-header::before {
  width: 0 !important; }

.grid-popover i {
  font-size: 2.5rem !important;
  margin: 5px 0;
  opacity: .6; }

.grid-popover .grid-text {
  font-size: 15px;
  font-weight: 600; }

.grid-popover .col-border {
  border: 1px solid rgba(117, 117, 117, 0.2); }

.grid-popover i {
  font-size: 1rem !important; }

.ul-bottom-border {
  border: 1px solid rgba(117, 117, 117, 0.2); }

.gauge-card {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  padding: 16px; }

.rasta-stripes .react-datepicker__week:nth-child(3n + 1) {
  background-color: #215005; }

.rasta-stripes .react-datepicker__week:nth-child(3n + 2) {
  background-color: #eea429; }

.rasta-stripes .react-datepicker__week:nth-child(3n + 3) {
  background-color: #a82a15; }

.red-border {
  border-color: #f00; }

.calender-back-class {
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background: white;
  border-radius: 10px;
  margin: 20px 0; }
  .calender-back-class .scheduler {
    margin: 6px auto !important; }
  .calender-back-class .ant-radio-button-wrapper-checked {
    background-color: #6200ea !important; }
    .calender-back-class .ant-radio-button-wrapper-checked:hover {
      color: white !important; }

.my-table-custom-class {
  background-color: white !important; }
  .my-table-custom-class table {
    margin-bottom: 0; }

.chatUserDisplayHideOnLg {
  min-width: 300px; }
  @media (max-width: 1199.98px) {
    .chatUserDisplayHideOnLg {
      display: none; } }

.contactLeftPanelDisplayHideOnLg {
  min-width: 300px; }
  @media (max-width: 1199.98px) {
    .contactLeftPanelDisplayHideOnLg {
      display: none; } }

.searchStyle {
  margin-left: auto; }
  @media (max-width: 575.98px) {
    .searchStyle {
      max-width: 100%; } }
  .searchStyle .close-search {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #6c757d; }

.filterSmallScreenmenu {
  display: none; }
  @media (max-width: 1199.98px) {
    .filterSmallScreenmenu {
      display: block; } }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.sidebar-overlay {
  display: none; }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

.mini-drawer-menu-icon-hide-topbar {
  display: hide;
  width: 35px; }

.popover-vehicle {
  font-size: 50px;
  display: flex;
  align-items: center;
  padding: 10px; }

.vehicle-icon-marker {
  font-size: 30px !important; }

.roe-colors {
  display: flex;
  flex-direction: row; }
  .roe-colors .color-left-side {
    width: 130px; }
  .roe-colors .color-right-side {
    flex: 1 0 0;
    padding-left: 10px; }

.react-strap-doc {
  padding: 0 15px; }

.roy-menu .roy-menu-list {
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 12px;
  letter-spacing: 1px; }
  .roy-menu .roy-menu-list:hover {
    background-color: #F4F5F7;
    color: #000;
    font-weight: 600; }

.roy-tooltip .roy-tooltip-inner {
  background-color: #42526e !important; }

.roy-tooltip .roy-tooltip-arrow::before {
  border-bottom-color: #42526e !important; }

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .profile-left-shade {
    margin-top: -200px !important; }
  .profile-right-shade {
    top: 0 !important; }
  .sidebar-overlay {
    display: block; } }

@media only screen and (min-width: 576px) and (max-width: 767.98px) {
  .sidebar-overlay {
    display: block; }
  .profile-left-shade {
    margin-top: -200px !important; }
  .profile-right-shade {
    top: 0 !important; }
  .drawer-handle-arrow-hide-topbar {
    display: none; }
  .mini-drawer-menu-icon-hide-topbar {
    display: block; } }

@media only screen and (max-width: 575.98px) {
  .sidebar-overlay {
    display: block; }
  .profile-left-shade {
    margin-top: -193px !important; }
  .profile-right-shade {
    top: 0 !important; }
  .send-req-btn {
    font-size: 12px; }
  .Profile-component {
    margin: -50px 0 -24px 0; }
  .display-1 {
    font-size: 3.5rem !important; }
  .display-2 {
    font-size: 3rem !important; }
  .display-3 {
    font-size: 2.5rem !important; }
  .display-4 {
    font-size: 2rem !important; }
  .feed-card-media img {
    height: 250px !important; }
  .react-strap-doc {
    padding: 0 15px; }
  .header-popover-width {
    min-width: 22rem; }
  .drawer-handle-arrow-hide-topbar {
    display: none; }
  .mini-drawer-menu-icon-hide-topbar {
    display: block; }
  .pa-0-small-screen {
    padding-right: 0px !important;
    padding-left: 0px !important; } }

@media (max-width: 575.98px) {
  .mobile-ma-8 {
    margin-top: 12px; } }

.header-popover .popover {
  max-width: 320px !important;
  min-width: 295px !important; }

.header-popover .mail-popover-body .mail-popover-block {
  background: #f1f2f7;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px; }
  .header-popover .mail-popover-body .mail-popover-block .mail-photo img {
    border-radius: 6px;
    height: 40px;
    margin-right: 10px;
    width: 40px;
    object-fit: cover; }
